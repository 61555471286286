import React, {useState, useEffect, useRef} from 'react'
import './categorii.css'
import { IoReturnUpBack } from "react-icons/io5";
import Sidebar from '../../sidebar/sidebar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { APIurl } from '../../../ApiService';
import { IoClose } from "react-icons/io5";
import { FaRegTrashAlt, FaCheck } from "react-icons/fa";
import { TbAlertHexagonFilled } from "react-icons/tb";
import { InfinitySpin } from 'react-loader-spinner';
import autoAnimate from '@formkit/auto-animate';
import { FaPlus } from 'react-icons/fa6';
import { IoCloseOutline } from "react-icons/io5";
import { set } from 'lodash';


const RenameCategoryPopup = ({category_id, category_name, closeRenameCategoryPopup, fetchCategories}) => {
    
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [rename_request_status, setRenameRequestStatus] = useState('');
    const [rename_category_loader, setRenameCategoryLoader] = useState(false);
    const [rename_category_input, setRenameCategoryInput] = useState('');
    const [rename_category_input_alert, setRenameCategoryInputAlert] = useState(false);
    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            closeRenameCategoryPopup();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleRenameCategoryInputChange = (event) => {
        setRenameCategoryInput(event.target.value);
    }

    const renameCategoryClicked = async() => {
        if(rename_category_input.trim() === ''){
            setRenameRequestStatus('');
            setRenameCategoryInputAlert(true);
            return;
        }else{
            console.log('New categ name: ' + rename_category_input);
            setRenameCategoryLoader(true);
            setRenameCategoryInputAlert(false);
            setRenameRequestStatus('');
            await renameCategory();
        }
    }

    const renameCategory = async() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            const reqData = {
                token: savedToken,
                id: category_id,
                name: rename_category_input,
                oldName: category_name,
            };
            try {
                const response = await axios.post(`${APIurl}/api/adminrenameproductcategory`, reqData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });    
                const responseStatus = response.data.status;
                const dataMessage = response.data.data;
                if (responseStatus === 'error') {
                    setRenameRequestStatus('error');
                    setRenameCategoryLoader(false);
                    return;
                } else {
                    if(dataMessage === 'Not admin'){
                        navigate('/login');
                    }else if(dataMessage === 'Product category not renamed'){
                        setRenameCategoryInput('');
                        setRenameCategoryInputAlert(false);
                        setRenameCategoryLoader(false);
                        setRenameRequestStatus('failed');
                        return;
                    }else if(dataMessage === 'Product category renamed'){
                        setRenameCategoryInput('');
                        setRenameCategoryInputAlert(false);
                        setRenameCategoryLoader(false);
                        setRenameRequestStatus('success');
                        fetchCategories();
                        return;
                    }
                }
            } catch (error) {
                setRenameCategoryLoader(false);
                console.error("Error fetching products categories:", error);
            }
        }else{
            navigate('/login');
        }
    }

    
    return(
        <div className='rename-category-popup'>
            <div className='rename-category-popup-content' ref={popupRef}>
                <div className='rename-category-popup-header'>
                    <p>Redenumire categorie</p>
                    <IoClose className='rename-category-popup-close-btn' onClick={() => closeRenameCategoryPopup()}/>
                </div>
                <div className='rename-category-popup-body'>
                {
                    rename_category_loader === true ? (
                        <div className='category_add_form_loader_div'>
                            <div className='category_add_form_loader'>
                                <InfinitySpin 
                                    visible={true}
                                    width="100"
                                    color="#3986FF"
                                />
                            </div>
                        </div>
                    ) : (
                        rename_request_status === '' ? (
                            <>
                                <div className='rename-category-popup-form'>
                                    <p>{category_name}</p>
                                    <p style={{fontSize: '20px'}}>→</p>
                                    <input 
                                        type="text" 
                                        placeholder="Nume nou..."  
                                        className='rename-category-popup-input' 
                                        onChange={handleRenameCategoryInputChange} 
                                        value={rename_category_input}
                                    />
                                </div>
                                {rename_category_input_alert !== false && (
                                    <div className='rename-category-input-alert'>
                                        <TbAlertHexagonFilled className='add-category-input-alert-icon'/>
                                        <p>Numele categoriei nu poate fi gol</p>
                                    </div>
                                )}
                                <div className='rename-category-popup-btn' onClick={() => renameCategoryClicked()}>
                                    <p>Redenumire</p>
                                </div>
                            </>
                        ) : rename_request_status === 'success' ? (
                            <div className='category-request-success'>
                                <FaCheck className='add-category-input-alert-icon'/>
                                <p>Categorie a fost redenumită</p>
                            </div>
                        ) : rename_request_status === 'error' ? (
                            <div className='category-request-error'>
                                <TbAlertHexagonFilled className='add-category-input-alert-icon'/>
                                <p>A apărut o eroare</p>
                            </div>
                        ) : rename_request_status === 'failed' ? (
                            <div className='category-request-failed'>
                                <TbAlertHexagonFilled className='add-category-input-alert-icon'/>
                                <p>Categorie nu a putut fi redenumită</p>
                            </div>
                        ) : null
                    )
                }
                </div>
            </div>
        </div>
    );
};

const DeleteCategoryPopup = ({category_id, category_name, products_nr, closeDeleteCategoryPopup, fetchCategories}) => {
    
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [delete_request_status, setDeleteRequestStatus] = useState('');
    const [delete_category_loader, setDeleteCategoryLoader] = useState(false);
    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            closeDeleteCategoryPopup();
        }
    };

    const deleteCategory = async() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            const reqData = {
                token: savedToken,
                id: category_id
            };
            try {
                const response = await axios.post(`${APIurl}/api/admindeleteproductcategory`, reqData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });    
                const responseStatus = response.data.status;
                const dataMessage = response.data.data;
                if (responseStatus === 'error') {
                    setDeleteCategoryLoader(false);
                    return;
                } else {
                    if(dataMessage === 'Not admin'){
                        navigate('/login');
                    }else if(dataMessage === 'Product category not deleted'){
                        setDeleteCategoryLoader(false);
                        setDeleteRequestStatus('failed');
                        return;
                    }else if(dataMessage === 'Product category deleted'){
                        setDeleteCategoryLoader(false);
                        setDeleteRequestStatus('success');
                        fetchCategories();
                        return;
                    }
                }
            } catch (error) {
                setDeleteCategoryLoader(false);
                setDeleteRequestStatus('error');
                console.error("Error fetching products categories:", error);
            }
        }else{
            navigate('/login');
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return(
        <div className='rename-category-popup'>
            <div className='rename-category-popup-content' ref={popupRef}>
                <div className='rename-category-popup-header'>
                    <p>Șterge categoria</p>
                    <IoClose className='rename-category-popup-close-btn' onClick={() => closeDeleteCategoryPopup()}/>
                </div>
                <div className='delete-category-popup-body'>
                    <p>Ești sigur că vrei să ștergi categoria <span style={{fontWeight: 500}}>{category_name}</span>?</p>
                    <p>Această categorie va fi ștearsă din toate produsele.</p>
                    <p>Categoria conține <span style={{fontWeight: 500}}>{products_nr}</span> produse care nu vor fi vizibile pe pagina de produse până nu le vei atribui altă categorie.</p>
                    <div className='delete-category-popup-btn-container'>
                        {delete_category_loader === true ? (
                            <div className='delete-category-popup-btn'>
                                <div className='category_add_form_loader_div'>
                                    <div className='category_add_form_loader'>
                                        <InfinitySpin 
                                            visible={true}
                                            width="100"
                                            color="#3986FF"
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            delete_request_status === '' ? (
                                <div className='delete-category-popup-btn' onClick={() => deleteCategory()}>
                                    <FaRegTrashAlt className='delete-category-popup-btn-icon'/>
                                    <p>Șterge</p>
                                </div>
                            ) : (
                                delete_request_status === 'success' ? (
                                    <div className='category-request-success'>
                                        <FaCheck className='add-category-input-alert-icon'/>
                                        <p>Categoria a fost stearsa</p>
                                    </div>
                                ) : delete_request_status === 'error' ? (
                                        <div className='category-request-error'>
                                            <TbAlertHexagonFilled className='add-category-input-alert-icon'/>
                                            <p>A aparut o eroare</p>
                                        </div>
                                ) : delete_request_status === 'failed' ? (
                                        <div className='category-request-failed'>
                                            <TbAlertHexagonFilled className='add-category-input-alert-icon'/>
                                            <p>Categoria nu a putut fi stearsa</p>
                                        </div>
                                ) : null
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const AddCategoryPopup = ({fetchCategories, closeAddCategoryPopup}) =>{
    const cookies = new Cookies();
    const navigate = useNavigate();

    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            closeAddCategoryPopup();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [isDragging, setIsDragging] = useState(false);
    const validFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    const [uploadedImage, setUploadedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(process.env.PUBLIC_URL + '/res/images/placeholder.jpg');
    const handleImageChange = async(e) => {
        const file = e.target.files[0];
        
        if (file) {
            if (!validFileTypes.includes(file.type)) {
                alert('Formatul imaginii nu este valid. Formatul permis este: jpg, jpeg, png');
                return;
            }
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
            setUploadedImage(file);
            console.log('uploaded image on frontend:');
            console.log(file);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith('image/')) {
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
        }
    };

    const [add_category_input, setAddCategoryInput] = useState('');
    const [add_category_input_alert, setAddCategoryInputAlert] = useState('');
    const [add_category_request_status, setAddCategoryRequestStatus] = useState('');
    const [add_category_loader, setAddCategoryLoader] = useState(false);

    const handleAddCategoryInputChange = (event) => {
        setAddCategoryInput(event.target.value);
    }

    const addCategoryClicked = async() => {
        if(add_category_input.trim() === ''){
            setAddCategoryRequestStatus('');
            setAddCategoryInputAlert(true);
            return;
        }else{
            console.log('New categ name: ' + add_category_input);
            setAddCategoryLoader(true);
            setAddCategoryInputAlert(false);
            setAddCategoryRequestStatus('');
            await addCategory();
        }
    }

    const addCategory = async() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            const formData = new FormData();
            formData.append('file', uploadedImage); // Key name 'file' to match multer's configuration
            formData.append('name', add_category_input);
            formData.append('token', savedToken);
            try {
                const response = await axios.post(`${APIurl}/api/admincreateproductcategory`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });    
                const responseStatus = response.data.status;
                const dataMessage = response.data.data;
                if (responseStatus === 'error') {
                    setAddCategoryRequestStatus('error');
                    setAddCategoryLoader(false);
                    return;
                } else {
                    if(dataMessage === 'Not admin'){
                        navigate('/login');
                    }else if(dataMessage === 'Product category already exists'){
                        setAddCategoryInput('');
                        setAddCategoryInputAlert(false);
                        setAddCategoryLoader(false);
                        setAddCategoryRequestStatus('already exists');
                        return;
                    }else if(dataMessage === 'Product category not created'){
                        setAddCategoryInput('');
                        setAddCategoryInputAlert(false);
                        setAddCategoryLoader(false);
                        setAddCategoryRequestStatus('failed');
                        return;
                    }else if(dataMessage === 'Product category created'){
                        setAddCategoryInput('');
                        setAddCategoryInputAlert(false);
                        setAddCategoryLoader(false);
                        setAddCategoryRequestStatus('success');
                        fetchCategories();
                        return;
                    }
                }
            } catch (error) {
                setAddCategoryLoader(false);
                console.error("Error fetching products categories:", error);
            }
        }else{
            navigate('/login');
        }
    }

    return(
        <div className="add-category-popup-container">
            <div className="add-category-popup" ref={popupRef}>
                <IoCloseOutline className='add-category-popup-close-btn' onClick={closeAddCategoryPopup}/>
                <div className="add-category-popup-header">
                    <h3>Adăugați o categorie nouă</h3>
                </div>
                <div className="add-category-popup-body">
                    <div className="add-category-popup-name">
                        <label htmlFor="name">Nume:</label>
                        <input type="text" placeholder="Nume categorie..." onChange={handleAddCategoryInputChange} value={add_category_input} className='add-category-popup-name-input' />
                    </div>
                    <p>Imagine categorie:</p>
                    <div className='add-category-image-upload-section'>
                        <div className='add-product-container-left-content-image-upload-box'
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        >
                            <input
                                type='file'
                                id='file-upload'
                                className='file-input'
                                accept='image/*'
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor='file-upload' className='add-product-image-upload-label'>
                                <FaPlus className='add-product-container-left-content-image-upload-icon' />
                                <p>Drag & Drop la imagine sau apasă pentru a selecta din fisiere</p>
                            </label>
                        </div>

                        <div className='add-category-image-preview'>
                            <img src={imagePreview} alt='Category image preview' />
                        </div>
                    </div>
                </div>
                <div className="add-category-popup-footer">
                    {add_category_loader === true ? (
                        <div className='category_add_form_loader_div'>
                            <div className='category_add_form_loader'>
                                <InfinitySpin 
                                    visible={true}
                                    width="100"
                                    color="#3986FF"
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='category-add-form-btn' onClick={() => addCategoryClicked()}>
                                <p>Adaugă</p>
                            </div>
                        </>
                    )}

                    {add_category_input_alert != false ? (
                        <div className='add-category-input-alert'>
                            <TbAlertHexagonFilled className='add-category-input-alert-icon'/>
                            <p>Numele categoriei nu poate fi gol</p>
                        </div>
                    ) : null}

                    {add_category_request_status !== '' ? (
                        <div className='add-category-request-status'>
                            {add_category_request_status === 'success' ? (
                                <div className='category-request-success'>
                                    <FaCheck className='add-category-input-alert-icon'/>
                                    <p>Categoria a fost creata</p>
                                </div>
                            ) : null}
                            {add_category_request_status === 'error' ? (
                                <div className='category-request-error'>
                                    <TbAlertHexagonFilled className='add-category-input-alert-icon'/>
                                    <p>Eroare la crearea categoriei</p>
                                </div>
                            ) : null}
                            {add_category_request_status === 'already exists' ? (
                                <div className='category-request-already-exists'>
                                    <TbAlertHexagonFilled className='add-category-input-alert-icon'/>
                                    <p>Categoria exista deja</p>
                                </div>
                            ) : null}
                            {add_category_request_status === 'failed' ? (
                                <div className='category-request-failed'>
                                    <TbAlertHexagonFilled className='add-category-input-alert-icon'/>
                                    <p>Categoria nu a putut fi creata</p>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

const EditCategoryImagePopup = ({category_id, category_name, closeEditCategoryImagePopup}) => {

    const cookies = new Cookies();
    const navigate = useNavigate();

    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            closeEditCategoryImagePopup();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // grab existing image from server
        grabExistingImage();
    }, []);

    const grabExistingImage = async() =>{
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            const tokenData = {
                token: savedToken,
                id: category_id,
            };
            try {
                const response = await axios.post(`${APIurl}/api/getcategoryimage`, tokenData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });    
                const responseStatus = response.data.status;
                const dataMessage = response.data.data;
                if (responseStatus === 'error') {
                    return;
                } else {
                    if(dataMessage === 'Category image not found'){
                        return;
                    }else{
                        console.log(response.data.image);
                        console.log('Category image fetched');
                        const response_data = response.data.image;
                        setImagePreview(response_data);
                        return;
                    }
                }
            } catch (error) {
                console.error("Error fetching category image:", error);
            }
        }else{
            navigate('/login');
        }
    }

    const [isDragging, setIsDragging] = useState(false);
    const validFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    const [uploadedImage, setUploadedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(process.env.PUBLIC_URL + '/res/images/placeholder.jpg');
    const handleImageChange = async(e) => {
        const file = e.target.files[0];
        
        if (file) {
            if (!validFileTypes.includes(file.type)) {
                alert('Formatul imaginii nu este valid. Formatul permis este: jpg, jpeg, png');
                return;
            }
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
            setUploadedImage(file);
            console.log('uploaded image on frontend:');
            console.log(file);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith('image/')) {
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
        }
    };

    const [add_category_request_status, setAddCategoryRequestStatus] = useState('');
    const [add_category_loader, setAddCategoryLoader] = useState(false);

    const changeImage = async() =>{
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            const formData = new FormData();
            formData.append('file', uploadedImage); 
            formData.append('id', category_id);
            formData.append('name', category_name);
            formData.append('token', savedToken);
            try {
                const response = await axios.post(`${APIurl}/api/editcategoryimage`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });    
                const responseStatus = response.data.status;
                const dataMessage = response.data.data;
                if (responseStatus === 'error') {
                    setAddCategoryRequestStatus('error');
                    setAddCategoryLoader(false);
                    return;
                } else {
                    if(dataMessage === 'Not admin'){
                        navigate('/login');
                    }else if(dataMessage === 'Category image not updated'){
                        setAddCategoryRequestStatus('failed');
                        setAddCategoryLoader(false);
                        return;
                    }else if(dataMessage === 'Category image updated'){
                        setAddCategoryRequestStatus('success');
                        setAddCategoryLoader(false);
                        return;
                    }
                }
            } catch (error) {
                setAddCategoryLoader(false);
                console.error("Error fetching products categories:", error);
            }
        }else{
            navigate('/login');
        }
    }

    return(
        <div className="add-category-popup-container">
            <div className="add-category-popup" ref={popupRef}>
                <IoCloseOutline className='add-category-popup-close-btn' onClick={closeEditCategoryImagePopup}/>
                <div className="add-category-popup-header">
                    <h3>Modifica imaginea categoriei</h3>
                </div>
                <div className="add-category-popup-body">
                    <p>Imagine categorie:</p>
                    <div className='add-category-image-upload-section'>
                        <div className='add-product-container-left-content-image-upload-box'
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        >
                            <input
                                type='file'
                                id='file-upload'
                                className='file-input'
                                accept='image/*'
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor='file-upload' className='add-product-image-upload-label'>
                                <FaPlus className='add-product-container-left-content-image-upload-icon' />
                                <p>Drag & Drop la imagine sau apasă pentru a selecta din fisiere</p>
                            </label>
                        </div>

                        <div className='add-category-image-preview'>
                            <img src={imagePreview} alt='Category image preview' />
                        </div>
                    </div>
                </div>
                <div className="add-category-popup-footer">
                    {add_category_loader === true ? (
                        <div className='category_add_form_loader_div'>
                            <div className='category_add_form_loader'>
                                <InfinitySpin 
                                    visible={true}
                                    width="100"
                                    color="#3986FF"
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='category-add-form-btn' onClick={() => changeImage()}>
                                <p>Salveaza</p>
                            </div>
                        </>
                    )}

                    {add_category_request_status !== '' ? (
                        <div className='add-category-request-status'>
                            {add_category_request_status === 'success' ? (
                                <div className='category-request-success'>
                                    <FaCheck className='add-category-input-alert-icon'/>
                                    <p>Imaginea a fost schimbata</p>
                                </div>
                            ) : null}
                            {add_category_request_status === 'failed' ? (
                                <div className='category-request-error'>
                                    <TbAlertHexagonFilled className='add-category-input-alert-icon'/>
                                    <p>Imaginea nu a putut fi schimbata</p>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

function Categorii() {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const redirectToProducts = () => {
        navigate('/administrator/produse');
    }

    const [categories, setCategories] = useState([]);

    const fetchCategories = async() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            const tokenData = {
                token: savedToken
            };
            try {
                const response = await axios.post(`${APIurl}/api/admingetproductcategories`, tokenData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });    
                const responseStatus = response.data.status;
                const dataMessage = response.data.data;
                if (responseStatus === 'error') {
                    return;
                } else {
                    if(dataMessage === 'Not admin'){
                        navigate('/login');
                    }else{
                        console.log(response.data.product_categories);
                        console.log('Product categories fetched');
                        const products_categories = response.data.product_categories;
                        console.log(products_categories);
                        setCategories(products_categories);
                        return;
                    }
                }
            } catch (error) {
                console.error("Error fetching products categories:", error);
            }

        }else{
            navigate('/login');
        }
    }

    useEffect(() => {
        fetchCategories();
    }, []);

    const [rename_category_popup, setRenameCategoryPopup] = useState(false);
    const [selected_category, setSelectedCategory] = useState(null);
    const openRenameCategoryPopup = (category) => {
        setSelectedCategory(category);
        setRenameCategoryPopup(true);
    }
    const closeRenameCategoryPopup = () => {
        setRenameCategoryPopup(false);
    }

    const [delete_category_popup, setDeleteCategoryPopup] = useState(false);
    const openDeleteCategoryPopup = (category) => {
        setSelectedCategory(category);
        setDeleteCategoryPopup(true);
    }
    const closeDeleteCategoryPopup = () => {
        setDeleteCategoryPopup(false);
    }  
    
    const [add_category_popup, setAddCategoryPopup] = useState(false);
    const openAddCategoryPopup = () => {
        setAddCategoryPopup(true);
    }
    const closeAddCategoryPopup = () => {
        setAddCategoryPopup(false);
    }  

    const [edit_category_image_popup, setEditCategoryImagePopup] = useState(false);
    const openEditCategoryImagePopup = (category) => {
        setSelectedCategory(category);
        setEditCategoryImagePopup(true);
    }
    const closeEditCategoryImagePopup = () => {
        setEditCategoryImagePopup(false);
    }  


    const categListParent = useRef(null)
    useEffect(() => {
        categListParent.current && autoAnimate(categListParent.current)
    }, [categListParent])

    return (
        <div className='adminPage'>
            <Sidebar activeTab={'produse'}/>
            <div className='comenziSection'>
                <div className='produseSubcategoryHeader'>
                    <IoReturnUpBack className='produseHeaderBackArrow' onClick={redirectToProducts}/>
                    <p className='produseHeaderTitle'>Modifică categoriile</p>
                </div>

                <div className='produse-container'>
                    <p className='produse-container-directory'><span style={{opacity: 0.4, cursor: 'pointer'}} onClick={redirectToProducts}>Toate Produsele / </span>Modifică Categoriile</p>

                    <div className='categories-section'>
                        <div className='categories-container categories-container-left'>
                            <div className='categories-table-header'>
                                <p>Id</p>
                                <p>Categorie</p>
                                <p>Nr. de produse</p>
                            </div>
                            <div className='categories-table-body' ref={categListParent}>
                                {categories != undefined && categories.length > 0 ? (
                                    categories.map((category, index) => (
                                        <div className='categories-table-row' key={'category' + index}>
                                            <p>{index + 1}</p>
                                            <p>{category.name}</p>
                                            <p>{category.products_nr}</p>
                                            <div className='category-edit-button' onClick={() => openRenameCategoryPopup(category)}>
                                                <p>Redenumire</p>
                                            </div>
                                            <div className='category-edit-button' onClick={() => openEditCategoryImagePopup(category)}>
                                                <p>Imagine</p>
                                            </div>
                                            <div className='category-delete-button' onClick={() => openDeleteCategoryPopup(category)}>
                                                <p>Șterge</p>
                                            </div>
                                        </div>
                                        ))
                                ) : (
                                    <p>No categories found</p>
                                )}
                            </div>
                        </div>

                        <div className=' categories-container-right'>
                            <div className='category-add-form-btn' onClick={openAddCategoryPopup}>
                                <p>Adauga categorie</p>
                            </div>
                        </div>

                    </div>

                    {rename_category_popup === true ? (
                        <RenameCategoryPopup category_id={selected_category.id} category_name={selected_category.name} closeRenameCategoryPopup={closeRenameCategoryPopup} fetchCategories={fetchCategories}/>
                    ) : null}

                    {delete_category_popup === true ? (
                        <DeleteCategoryPopup category_id={selected_category.id} category_name={selected_category.name} products_nr={selected_category.products_nr} closeDeleteCategoryPopup={closeDeleteCategoryPopup} fetchCategories={fetchCategories}/>
                    ) : null}

                    {add_category_popup === true ? (
                        <AddCategoryPopup closeAddCategoryPopup={closeAddCategoryPopup} fetchCategories={fetchCategories}/>
                    ) : null}

                    {edit_category_image_popup === true ? (
                        <EditCategoryImagePopup category_id={selected_category.id} category_name={selected_category.name} closeEditCategoryImagePopup={closeEditCategoryImagePopup}/>
                    ) : null}

                </div>

            </div>
        </div>
    )
}

export default Categorii