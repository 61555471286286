import React, { useState, useEffect, useRef, useContext } from 'react'
import './header.css'
import { FaCartShopping } from "react-icons/fa6";
import autoAnimate from '@formkit/auto-animate';
import { CartContext } from '../shop/cart/cartProvider';
import { IoMenuOutline, IoCloseOutline, IoCartOutline } from "react-icons/io5";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { set } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { APIurl } from '../../ApiService';

function Header() {
    const { getCartItemCount } = useContext(CartContext);
    const cartItemsCount = getCartItemCount();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () =>{
        setMobileMenuOpen(!mobileMenuOpen);
    }

    const menuBtnListRef = useRef(null);
    useEffect(() => {
        if (menuBtnListRef.current) {
            autoAnimate(menuBtnListRef.current);
        }
    }, []);

    const cookies = new Cookies();
    const navigate = useNavigate();

    const [showBtn, setShowBtn] = useState(false);
    useEffect(() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            checkIfLoggedIn(savedToken);
        }else{
            return;
        }
    }, []);

    const checkIfLoggedIn = async(savedToken) =>{
        const tokenData = {
            token: savedToken
        };
        try {
            const response = await axios.post(`${APIurl}/api/checkForABtn`, tokenData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
    
            if (responseStatus === 'error') {
                setShowBtn(false);
                return;
            } else {
                if(dataMessage === 'Dont show'){
                    setShowBtn(false);
                    return;
                }else if(dataMessage === 'Show'){
                    setShowBtn(true);
                    return;
                }
            }
        } catch (error) {
            setShowBtn(false);
        }
    }

    return (
        <div className='headerSection'>
            <div className='headerContainer'>
                <div className='headerMenuMobile'>
                    <div className='headerMenuMobileToggleBtn' ref={menuBtnListRef} onClick={toggleMobileMenu} style={{background: mobileMenuOpen ? '#FF5A2E' : null, color: mobileMenuOpen ? 'white' : null}}>
                        {mobileMenuOpen === true ? <IoCloseOutline className='headerMenuMobileMenuIcon'/> : <IoMenuOutline className='headerMenuMobileMenuIcon'/>}
                    </div>
                    <Link to={'/neworder'} className='headerLogoMobile'>
                        <img src='\res\images\myBoardsLogoShadow.png' alt='Logo'/>
                    </Link>
                </div>
                <div className='headerMenuMobile'>
                    <Link to="/myaccount" className='headerMenuMobileIconBox'>
                        <HiOutlineUserCircle className='headerMenuMobileMenuIcon'/>
                    </Link>
                    <Link to="/cart" className='headerMenuMobileIconBox'>
                        <IoCartOutline className='headerMenuMobileMenuIcon'/>
                    </Link>
                </div>
                <Link to={'/neworder'} className='headerLogo'>
                    <img src='\res\images\myBoardsLogoShadow.png' alt='Logo'/>
                </Link>
                <div className='headerMenu'>
                    
                    <Link to="/shop" className='headerMenuItem'> 
                        <p>Shop</p>
                    </Link> 
                    <Link to="/neworder" className='headerMenuItem'>
                        <p>Cut & Edge</p>
                    </Link>
                    <Link to="/ordershistory" className='headerMenuItem'>
                        <p>Orders</p>
                    </Link>
                    <Link to="/myaccount" className='headerMenuItem'>
                        <p>My Account</p>
                    </Link>
                    
                    <Link to="/cart" className='headerCart'>
                        <FaCartShopping/>
                        {cartItemsCount > 0 ? 
                            <div className='headerCartCount'>
                                <p className='headerCartCount-value'>{cartItemsCount}</p>
                            </div> : null
                        }
                    </Link> 
                </div>

                {mobileMenuOpen === true ? (
                    <div className='headerMobileDropdownMenu'>
                        
                        <Link to="/shop" className='headerMobileMenuItem'>
                            <p>Shop</p>
                        </Link>
                        <Link to="/neworder" className='headerMobileMenuItem'>
                            <p>Cut & Edge</p>
                        </Link>
                        <Link to="/ordershistory" className='headerMobileMenuItem'>
                            <p>Orders history</p>
                        </Link>
                    </div>
                ) : null}

                {showBtn === true ? (
                    <Link to="/administrator" className='headerABtn'>
                        <p>Admin panel</p>
                    </Link>
                ) : null}

            </div>
        </div>
    )
}

export default Header