import React, { useState, useEffect } from 'react'
import './cereriSup.css'
import { IoIosSearch } from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import Sidebar from '../sidebar/sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import Loader from "react-js-loader";
import { FaEye } from "react-icons/fa";


import { setTicketPopupData } from './support_ticket_data';
import SupportTicketPopup from './supportTicketPopup';

import { APIurl } from '../../ApiService';

function CereriSup() {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [hidePage, setHidePage] = useState(true);

    const [showLoader, setShowLoader] = useState(false);
    const [support_tickets, setSupportTickets] = useState([]);

    const fetchSupportTickets = async(userToken) =>{
        setShowLoader(true);
        const tokenData = {
            token: userToken
        };
        try {
            const response = await axios.post(`${APIurl}/api/admingetsupptickets`, tokenData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
    
            if (responseStatus === 'error') {
                setShowLoader(false);
                return;
            } else {
                if(dataMessage === 'Not admin'){
                    navigate('/login');
                }else{
                    setHidePage(false);
                    console.log(response.data);
                    const fetched_support_tickets = response.data.support_tickets;
                    setSupportTickets(fetched_support_tickets);
                    setShowLoader(false);
                    return;
                }
            }
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle or log the error
            setShowLoader(false);
            //navigate('/neworder');
        }
    }
    useEffect(() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            fetchSupportTickets(savedToken);
        }else{
            navigate('/login');
        }
    }, []);

    const [ticketMessagePopupVisible, setTicketMessagePopupVisible] = useState(false);
    const openTicketMessagePopup = async(ticket) =>{
        await setTicketPopupData(ticket);
        setTicketMessagePopupVisible(true);
    }
    const closeTicketMessagePopup = () =>{
        setTicketMessagePopupVisible(false);
    }

    return (
        <div className='adminPage'>
            <Sidebar activeTab={'cereri_suport'}/>
            <div className='cererisupSection'>
                {showLoader === true ? (
                    <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
                ) : (
                    <>
                        <div className='cererisupHeader'>
                            <p className='cererisupHeaderTitle'>Cereri suport</p>
                        </div>
                        <div className='cererisupListSection'>
                            <p style={{textAlign:'left', opacity: '0.5'}}>Cererile de suport sunt ordonate de la cele mai noi pana la cele mai vechi</p>
                            {support_tickets?.length > 0 ? (
                                support_tickets.map((ticket, index) => (
                                    <div className='cereriSuppListItem' key={'ticket' + index}>
                                        <div className='cereriSuppListItemGridItem'>
                                            <p>Ticket ID: <b>{ticket.id}</b></p>
                                        </div>
                                        <div className='cereriSuppListItemGridItem'>
                                            <p>Data: <b>{ticket.date}</b></p>
                                        </div>
                                        <div className='cereriSuppListItemGridItem'>
                                            <p>Client: <b>{ticket.email}</b></p>
                                        </div>
                                        <div className='comenziDownloadOrderSummaryBtn' onClick={() => openTicketMessagePopup(ticket)}>
                                            <FaEye />
                                            <p>Vezi continut</p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>0 cereri de suport</p>
                            )}
                        </div>
                    </>
                )}

                {ticketMessagePopupVisible === true ? (
                    <SupportTicketPopup 
                        closeTicketPopup={closeTicketMessagePopup} 
                    />
                    ) : null 
                }
                
            </div>
        </div>
    )
}

export default CereriSup