

let products = [];
let delivery_info = null;
let date = '';
let price = 0;
let order_id = null;

export const setNewPopupData = async(data, orderId) =>{
    console.log('order data in file:');
    console.log(data);
    products = JSON.parse(data.products);
    delivery_info = data.delivery_info;
    date = data.date;
    price = data.price;
    order_id = orderId;
};

export const getPopupData = async() =>{
    return ({
        products: products,
        delivery_info: delivery_info,
        date: date,
        price: price,
        order_id: order_id
    });
};


