import React, { useState, useEffect, useContext } from 'react'
import './loginPage.css'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { FiAlertTriangle } from "react-icons/fi";
import Loader from "react-js-loader";
import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../shop/cart/cartProvider';

import { APIurl } from '../../ApiService';

function LoginPage() {
    const { replaceCartItems } = useContext(CartContext);
    //  initialize cookies
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [loginForm, setLoginForm] = useState({
        email: '',
        password: ''
    });

    const [loginFormError, setLoginFormError] = useState(null);
    const [loginFormServerError, setLoginFormServerError] = useState(false);
    const [displayLoginLoading, setDisplayLoginLoading] = useState(false);

    const handleLoginFormChanges = (fieldID, event) => {
        const newInput = event.target.value;
        if(fieldID == 'email'){
            // change the login form state
            let newState = {...loginForm};
            newState.email = newInput;
            setLoginForm(newState);
        }else if(fieldID == 'password'){
            // change the login form state
            let newState = {...loginForm};
            newState.password = newInput;
            setLoginForm(newState);
        }
    };

    const validateLoginForm = async() => {
        setLoginFormError(null);
        setLoginFormServerError(false);
        setDisplayLoginLoading(true);
        try{
            // validate the email
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (loginForm.email == null || !emailRegex.test(loginForm.email) || loginForm.password == null) {
                setLoginFormError('Invalid email or password. Please try again.');
                setDisplayLoginLoading(false);
                return;
            }

            // login request
            const userData = {
                email: loginForm.email.toLowerCase(),
                password: loginForm.password,
            };

            const response = await axios.post(`${APIurl}/api/login`, userData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;

            //  verify the response
            if(responseStatus == 'error'){  //  if server gave error
                setLoginFormServerError(true);
                setDisplayLoginLoading(false);
                return;
            }else{  //  if server responded ok
                if(dataMessage == 'Wrong credentials'){
                    setLoginFormError('Invalid email or password. Please try again.');
                    setDisplayLoginLoading(false);
                    return;
                }else if(dataMessage == 'Success'){
                    setDisplayLoginLoading(false);
                    const newToken = response.data.token;
                    const userMail = response.data.email;
                    const phone = response.data.phone;
                    const cart = response.data.cart;
                    cookies.set("authToken", newToken);
                    cookies.set("email", userMail);
                    cookies.set("phone", phone);
                    const savedToken = cookies.get("authToken");
                    console.log('cart:');
                    console.log(cart);
                    replaceCartItems(cart);
                    if(savedToken){
                        //console.log("🔐 Here's your saved token 🔐 \n" + savedToken);
                        navigate('/neworder');
                    }
                    return;
                }
            }
        }catch(error){
            console.error('Error signing in user:', error);
        }
    }

    const checkToken = async(savedToken) =>{
        const tokenData = {
            token: savedToken,
        };

        const response = await axios.post(`${APIurl}/api/checkToken`, tokenData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const responseStatus = response.data.status;
        const dataMessage = response.data.data;

        //  verify the response
        if(responseStatus == 'error'){  //  if server gave error
            return;
        }else{  //  if server responded ok
            if(dataMessage == 'Good token'){
                const userMail = response.data.email;
                const phone = response.data.phone;
                cookies.set("email", userMail);
                cookies.set("phone", phone);
                navigate('/neworder');
                return;
            }else{
                return;
            }
        }
    }

    useEffect(() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are trying to log in with this token 🔐 \n" + savedToken);
            checkToken(savedToken);
        }
    }, []);

    return (
        <div className='loginSection'>
            <div className='loginContainer'>
                <div className='loginContainerLogo'>
                    <img src='res\images\myBoardsLogoShadow.png' alt='Logo' className='loginLogoImg'/>
                </div>
                <div className='loginContainerContent'>
                    <div className='loginCard'>
                        <h3 style={{paddingBottom: '25px', fontWeight: '500'}}>Login</h3>
                        <div className='loginForm'>
                            <div className='loginFormSection'>
                                <div className='loginFormLabelBox'>
                                    <p>Email address</p>
                                </div>
                                <div className='loginFormInputFieldBox'>
                                    {loginFormError !== null ? (
                                        <div className='registerFormAlert'>
                                            <FiAlertTriangle />
                                            <p>{loginFormError}</p>
                                        </div>
                                    ) : null}
                                    <input className='loginFormInputField'
                                        value={loginForm.email}
                                        onChange={(event) => handleLoginFormChanges('email', event)}
                                    />
                                </div>
                            </div>
                            <div className='loginFormSection'>
                                <div className='loginFormLabelBox'>
                                    <p>Password</p>
                                </div>
                                <div className='loginFormInputFieldBox'>
                                    <input className='loginFormInputField'
                                        value={loginForm.password}
                                        onChange={(event) => handleLoginFormChanges('password', event)}
                                        type='password'
                                    />
                                </div>
                            </div>
                        </div>
                        <Link to="/forgotpass" style={{fontSize: '14px', paddingTop: '5px', width: 'fit-content', textDecoration: 'none'}} className='loginToForgotPassword'>Forgot password</Link>
                        {loginFormServerError === true ? (
                            <div className='loginBtnBox'>
                                <p>Something went wrong...</p>
                            </div>
                        ) : null}
                        <div className='loginBtnBox'>
                            {displayLoginLoading === false ? (
                                <div className='loginBtn' onClick={() => validateLoginForm()}>
                                    <p>LOGIN</p>
                                </div>
                            ) : (
                                <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
                            )}
                        </div>
                        <p style={{paddingTop: '5px', fontSize: '16px'}}>Don't have an account? <Link to="/register" style={{textDecoration: 'none', color: 'black'}}><span className='loginToSignUpBtn'>Sign up</span></Link></p>
                    </div>
                    <Link to="/neworder" className='loginGuestBtn'>
                        <p>Continue as a guest</p>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LoginPage