import React, { useState, useEffect } from 'react'
import './sidebar.css'
import { FaRegListAlt, FaUsers } from "react-icons/fa";
import { MdOutlineContactSupport, MdOutlinePriceChange } from "react-icons/md";
import { FaUserShield } from "react-icons/fa6";
import { TbLogout2 } from "react-icons/tb";
import { IoGrid } from "react-icons/io5";
import { GiShop } from "react-icons/gi";
import { Link, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';

function Sidebar(props) {
    const cookies = new Cookies();
    const navigate = useNavigate();

    // const [user, setUser] = useState('');

    // useEffect(() => {
    //     const user  = cookies.get('email');
    //     setUser(user);
    // }, []);

    const [activeTab, setActiveTab] = useState(props.activeTab);

    const logout = async() => {
        await cookies.remove('authToken');
        await cookies.remove('email');
        await cookies.remove('phone');
        navigate('/login');
    }

    return (
        <div className='sidebarSection'>
            <div className='sidebarHeader'>
                <img src='\res\images\myBoardsLogoWhiteText.png' alt='Logo' className='Logo'/>
                <p>Admin: <b style={{fontWeight: '500'}}>{cookies.get('email')}</b></p>
            </div>
            <div className='sidebarMenu'>
                <Link to={'/administrator'} className={activeTab === 'comenzi' ? 'sidebarMenuItemActive' : 'sidebarMenuItem'}>
                    <FaRegListAlt />
                    <p>Comenzi</p>
                </Link>
                <Link to={'/administrator/comenzi-magazin'} className={activeTab === 'comenzi_magazin' ? 'sidebarMenuItemActive' : 'sidebarMenuItem'}>
                    <GiShop />
                    <p>Comenzi magazin</p>
                </Link>
                <Link to={'/administrator/cereri-suport'} className={activeTab === 'cereri_suport' ? 'sidebarMenuItemActive' : 'sidebarMenuItem'}>
                    <MdOutlineContactSupport />
                    <p>Cereri suport</p>
                </Link>
                <Link to={'/administrator/produse'} className={activeTab === 'produse' ? 'sidebarMenuItemActive' : 'sidebarMenuItem'}>
                    <IoGrid  />
                    <p>Produse magazin</p>
                </Link>
                <Link to={'/administrator/preturi-servicii'} className={activeTab === 'preturi_servicii' ? 'sidebarMenuItemActive' : 'sidebarMenuItem'}>
                    <MdOutlinePriceChange />
                    <p>Preturi Cut & Edge</p>
                </Link>
                <Link to={'/administrator/conturi-clienti'} className={activeTab === 'conturi_clienti' ? 'sidebarMenuItemActive' : 'sidebarMenuItem'}>
                    <FaUsers />
                    <p>Conturi clienti</p>
                </Link>
                <Link to={'/administrator/conturi-admini'} className={activeTab === 'conturi_administratori' ? 'sidebarMenuItemActive' : 'sidebarMenuItem'}>
                    <FaUserShield />
                    <p>Conturi administatori</p>
                </Link>
            </div>
            <div className='sidebarMenu'>
                <div className='sidebarMenuItem' onClick={logout}>
                    <TbLogout2 />
                    <p>Deconectare</p>
                </div>
            </div>
        </div>
    )
}

export default Sidebar