import React, {useState, useEffect, useRef} from 'react'
import './produseJmek.css'

import Sidebar from '../sidebar/sidebar';
import { FiSearch } from "react-icons/fi";
import { FaAngleDown, FaPlus  } from "react-icons/fa6";
import { MdCategory } from "react-icons/md";

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { APIurl } from '../../ApiService';
import autoAnimate from '@formkit/auto-animate';

function ProduseJmek() {

    const navigate = useNavigate();
    const cookies = new Cookies();
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [categories, setCategories] = useState([]);
    const fetchProducts = async() =>{
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            const tokenData = {
                token: savedToken,
            };
            try {
                const response = await axios.post(`${APIurl}/api/admingetproducts`, tokenData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });    
                const responseStatus = response.data.status;
                const dataMessage = response.data.data;
                if (responseStatus === 'error') {
                    setIsError(true);
                    setErrorMessage(dataMessage);
                    return;
                } else {
                    if(dataMessage === 'Not admin'){
                        navigate('/login');
                    }else if(dataMessage === 'Products not found'){
                        console.log('Products not found');
                        return;
                    }else{
                        console.log(response.data.products);
                        console.log('Products fetched');
                        const response_products = response.data.response_data.products;
                        setProducts(response_products);
                        setFilteredProducts(response_products);
                        const new_categories = response.data.response_data.categories;
                        console.log(new_categories);
                        const all_categ = {
                            id: 0,
                            name: 'Toate Produsele',
                        }
                        new_categories.unshift(all_categ);
                        setCategories(new_categories);
                        return;
                    }
                }
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        }else{
            navigate('/login');
        }
    }
    useEffect(() => {
        fetchProducts();
    }, []);

    const redirectToCategories = () => {
        navigate('/administrator/categorii');
    }

    const redirectToAddProduct = () => {
        navigate('/administrator/adauga-produs');
    }

    const redirectToEditProduct = (product_id) => {
        navigate('/administrator/editare-produs/' + product_id);
    }

    const [filtered_products, setFilteredProducts] = useState([]);
    const [category_filter, setCategoryFilter] = useState('');
    const [searchText, setSearchText] = useState('');
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const searchProducts = async () => {
        let new_filtered_products = products;
        // Filter by category if category_filter is not empty
        if (category_filter !== '' && category_filter !== 'Toate Produsele') {
            new_filtered_products = new_filtered_products.filter(product => product.category === category_filter);
        }
        // Filter by searchText if it's not empty (check if product name contains searchText)
        if (searchText.trim() !== '') {
            const lowerCaseSearchText = searchText.trim().toLowerCase();
            new_filtered_products = new_filtered_products.filter(product =>
                product.name.toLowerCase().includes(lowerCaseSearchText)
            );
        }
        setFilteredProducts(new_filtered_products);
    };

    // categories dropdown
    const CategDropDownParent = useRef(null)
    useEffect(() => {
        CategDropDownParent.current && autoAnimate(CategDropDownParent.current)
    }, [CategDropDownParent])
    const [categoriesDropwonOpen, setCategoriesDropwonOpen] = useState(false);
    const toggleCategoriesDropdown = () =>{
        setCategoriesDropwonOpen(!categoriesDropwonOpen);
    }
    const closeCategoriesDropdown = () =>{
        setCategoriesDropwonOpen(false);
    }

    const handleCategoryChange = (categoryID) =>{
        setCategoryFilter(categories[categoryID].name);
        closeCategoriesDropdown();
    }

    useEffect(() => {
        searchProducts();
    }, [searchText, category_filter]);

    const placeholderImage = process.env.PUBLIC_URL + '/res/images/placeholder.jpg';

    return (
        <div className='adminPage'>
            <Sidebar activeTab={'produse'}/>
            <div className='comenziSection'>
                <div className='produseHeader'>
                    <p className='produseHeaderTitle'>Produse 📦</p>
                    <div className='produseHeaderActions'>
                        <div className='produse-search-field'>
                            <FiSearch className='shop-products-header-search-icon'/>
                            <input type="text" placeholder="Caută produs..." value={searchText} onChange={handleSearchChange}/>
                        </div>
                        <div className='produse-jmek-categories-dropdown' ref={CategDropDownParent}>
                            <div className='produse-jmek-categories-dropdown-top'style={{cursor: 'pointer'}} onClick={() => toggleCategoriesDropdown()}>
                                <div className='add-product-categories-dropdown-top-value'>
                                    <p>{category_filter!== '' ? category_filter : 'Sortează după categorie'}</p>
                                </div>
                                <FaAngleDown />
                            </div>
                            {categoriesDropwonOpen === true ?
                                (<div className='add-product-categories-dropdown-menu'>
                                {categories.map((category, index) => (
                                    <div key={'category' + index} className='add-product-categories-dropdown-menu-item' onClick={() => handleCategoryChange(index)}><p>{category.name}</p></div>
                                ))}
                                </div>) : null}
                        </div>
                    </div>
                </div>

                <div className='produse-container'>
                    <p className='produse-container-directory'>Toate Produsele</p>

                    <div className='produse-container-big-options-wrapper'>
                        <div className='produse-container-big-options'>
                            <div className='produse-container-big-options-item adauga-produs-btn' onClick={redirectToAddProduct}>
                                <FaPlus />
                                <p>Adaugă Produs</p>
                            </div>
                            <div className='produse-container-big-options-item modifica-categorii-btn' onClick={redirectToCategories}>
                                <MdCategory />
                                <p>Modifică Categoriile</p>
                            </div>
                        </div>
                        <p className='produse-gasite-text'>Produse găsite: {filtered_products.length}</p>
                    </div>

                    <div className='produse-container-list'>

                        {filtered_products.map((product, index) => (
                            <div key={'produse-container-list-item' + index} className='produse-container-list-item' onClick={() => redirectToEditProduct(product.id)}>
                                <div className='produse-container-list-item-image'>
                                    <img src={product.image !== null ? product.image : process.env.PUBLIC_URL + '/res/images/placeholder.jpg'} 
                                        alt='Wall panelling'
                                        onError={(e) => {
                                            e.target.onerror = null; // Prevents looping
                                            e.target.src = placeholderImage;
                                        }}
                                    />                            
                                </div>
                                <div className='produse-container-list-item-info'>
                                    <p className='produse-container-list-item-info-code'>#{product.id} / {product.category}</p>
                                    <p className='produse-container-list-item-info-title'>{product.name}</p>
                                    <p className='produse-container-list-item-info-stock'>{product.stock ? 'In Stock' : 'Out of Stock'}</p>
                                    <p className='produse-container-list-item-info-price'>£{product.price}</p>
                                </div>
                            </div>
                        ))}
                        
                    </div>


                </div>

            </div>
        </div>
    )
}

export default ProduseJmek