import React, { createContext, useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { APIurl } from '../../../ApiService';
import axios from 'axios';
import { add, get, set } from 'lodash';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState(() => {
        // Initialize state with items from localStorage or an empty array if not found
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
    });
    const cookies = new Cookies();

    // Function to add an item to the cart
    const addItemToCart = async(product) => {
        console.log('product received by cart provider:');
        console.log(product);
        // make API call to add to cart
        try{
            let baseOptions = [];
            for(let i = 0; i < product.baseOptions.length; i++){
                const option = product.baseOptions[i];
                if(option.dropdown_value !== ''){
                    baseOptions.push({
                        name: option.name,
                        value: option.dropdown_value,
                    });
                }
            }
            let squareMeterFixedOptions = [];
            let squareMeterRangeOptions = [];
            for(let i = 0; i < product.squareMeterOptions.length; i++){
                const option = product.squareMeterOptions[i];
                if(option.dropdown_value !== undefined && option.dropdown_value !== null && option.dropdown_value !== ''){
                    squareMeterFixedOptions.push({
                        name: option.name,
                        value: option.dropdown_value,
                    });
                }else if(option.input_value !== undefined && option.input_value !== null && option.input_value !== ''){
                    squareMeterRangeOptions.push({
                        name: option.name,
                        value: option.input_value,
                    });
                }
            }
            let fixedPercentageOptions = [];
            for(let i = 0; i < product.fixedPercentageOptions.length; i++){
                const option = product.fixedPercentageOptions[i];
                if(option.dropdown_value !== ''){
                    fixedPercentageOptions.push({
                        name: option.name,
                        value: option.dropdown_value,
                    });
                }
            }
            let rangePercentageOptions = [];
            for(let i = 0; i < product.rangePercentageOptions.length; i++){
                const option = product.rangePercentageOptions[i];
                if(option.input_value !== ''){
                    rangePercentageOptions.push({
                        name: option.name,
                        value: option.input_value,
                    });
                }
            }
            let additionalOptions = [];
            for(let i = 0; i < product.additionalOptions.length; i++){
                const option = product.additionalOptions[i];
                if(option.dropdown_value !== ''){
                    additionalOptions.push({
                        name: option.name,
                        value: option.dropdown_value,
                    });
                }
            }

            const userToken = cookies.get('authToken');
            const prevCart = localStorage.getItem('cart');
            const req_data = {
                product: {
                    product_id: product.id,
                    quantity: product.quantity,
                    baseOptions: baseOptions,
                    squareMeterFixedOptions: squareMeterFixedOptions,
                    squareMeterRangeOptions: squareMeterRangeOptions,
                    fixedPercentageOptions: fixedPercentageOptions,
                    rangePercentageOptions: rangePercentageOptions,
                    additionalOptions: additionalOptions,
                },
                userToken: userToken,
                prevCart: prevCart
            };
            const response = await axios.post(`${APIurl}/api/addtocartproduct`, req_data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('response from add to cart API call:');
            console.log(response.data);
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
            const cartMessage = response.data.message;
            const loggedInUser = response.data.loggedInUser;
            if (responseStatus !== 'error') {
                if (dataMessage === 'Product added to cart' && cartMessage === 'Good') {
                    if(loggedInUser === true){
                        const newCartItems = response.data.newCartList;
                        setCartItems([...newCartItems]);
                    }else{
                        const newProductInCart = response.data.newCartList;
                        if(newProductInCart !== null){
                            console.log('Prev cart items:');
                            console.log(cartItems);
                            const newCartItems = addOrUpdateCartProduct(cartItems, newProductInCart);
                            setCartItems([...newCartItems]);
                            console.log('New cart items:');
                            console.log(newCartItems);
                        }
                    }
                }
            }
        }catch(error){
            console.error(error);
        }
    };

    useEffect(() => {
        // save the cart items to cookies
        const cartItemsAsString = JSON.stringify(cartItems);
        localStorage.setItem('cart', cartItemsAsString);
    }, [cartItems]);

    function addOrUpdateCartProduct(cartProductsList, newProduct) {
        // Check if the new product already exists in the cart based on matching properties
        const existingProductIndex = cartProductsList.findIndex((product) => {
            return (
                product.product_id === newProduct.product_id && // Match product ID
                JSON.stringify(product.baseOptions) === JSON.stringify(newProduct.baseOptions) && // Match base options
                JSON.stringify(product.squareMeterFixedOptions) === JSON.stringify(newProduct.squareMeterFixedOptions) && // Match square meter fixed options
                JSON.stringify(product.squareMeterRangeOptions) === JSON.stringify(newProduct.squareMeterRangeOptions) && // Match square meter range options
                JSON.stringify(product.fixedPercentageOptions) === JSON.stringify(newProduct.fixedPercentageOptions) && // Match fixed percentage options
                JSON.stringify(product.rangePercentageOptions) === JSON.stringify(newProduct.rangePercentageOptions) && // Match range percentage options
                JSON.stringify(product.additionalOptions) === JSON.stringify(newProduct.additionalOptions) // Match additional options
            );
        });
        if (existingProductIndex > -1) {
            // Product already exists in the cart, update its quantity
            const newQuantity = parseInt(cartProductsList[existingProductIndex].quantity) + parseInt(newProduct.quantity);
            cartProductsList[existingProductIndex].quantity = newQuantity;
            console.log('Product already exists. Updated quantity.');
        } else {
            // Product does not exist in the cart, add it as a new product
            cartProductsList.push(newProduct);
            console.log('Added new product to cart.');
        }
        return cartProductsList;
    }

    // Function to remove an item from the cart
    const removeItemFromCart = (itemId) => {
        setCartItems((prevItems) => prevItems.filter(item => item.id !== itemId));
    };

    // Function to clear the cart
    const clearCart = () => {
        setCartItems([]);
    };

    const getCartItemCount = () => {
        let numberOfItems = 0;
        cartItems.forEach(item => {
            numberOfItems += parseInt(item.quantity);
        });
        return numberOfItems;
    };

    const replaceCartItems = (newCartItems) => {
        setCartItems(newCartItems);
    };

    const updateQuantityInCart = async(itemId, quantity) => {
        try{
            const userToken = cookies.get('authToken');
            const prevCart = localStorage.getItem('cart');
            const prevCartArray = JSON.parse(prevCart);
            const req_data = {
                item_id: itemId,
                quantity: quantity,
                userToken: userToken,
                prevCart: prevCartArray
            };
            const response = await axios.post(`${APIurl}/api/updateproductquantity`, req_data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
            if(responseStatus == 'error'){  //  if server gave error
                return;
            }else{  //  if server responded ok
                if(dataMessage == 'Product quantity updated'){
                    const newCartItems = response.data.newCartList;
                    setCartItems([...newCartItems]);
                    console.log('Cart items updated:');
                    console.log(newCartItems);
                    return newCartItems;
                }
            }
        }catch(error){
            console.error(error);
        }
    };

    const getCartItems = async() => {
        try{
            const userToken = cookies.get('authToken');
            const prevCart = localStorage.getItem('cart');
            const prevCartArray = JSON.parse(prevCart);
            const req_data = {
                userToken: userToken,
                prevCart: prevCartArray
            };
            const response = await axios.post(`${APIurl}/api/getcartprice`, req_data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
            if(responseStatus == 'error'){  //  if server gave error
                setCartItems([]);
                let emptyCartItems = [];
                return emptyCartItems;
            }else{  //  if server responded ok
                if(dataMessage == 'Cart price fetched'){
                    const newCartItems = response.data.newCartList;
                    setCartItems([...newCartItems]);
                    console.log('Cart items fetched:');
                    console.log(newCartItems);
                    return newCartItems;
                }
            }
        }catch(error){
            console.error(error);
            return cartItems;
        }
    }

    const removeProductFromCart = async(itemId) => {
        try{
            const userToken = cookies.get('authToken');
            const prevCart = localStorage.getItem('cart');
            const prevCartArray = JSON.parse(prevCart);
            const req_data = {
                item_id: itemId,
                userToken: userToken,
                prevCart: prevCartArray
            };
            const response = await axios.post(`${APIurl}/api/removeproductfromcart`, req_data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
            console.log('response from remove product from cart API call:');
            console.log(response.data);
            if(responseStatus == 'error'){  //  if server gave error
                return cartItems;
            }else{  //  if server responded ok
                if(dataMessage == 'Product removed from cart'){
                    const newCartItems = response.data.newCartList;
                    console.log('Cart items after remove:');
                    console.log(newCartItems);
                    setCartItems([...newCartItems]);
                    return newCartItems;
                }else{
                    console.log('Error removing product from cart');
                    return cartItems;
                }
            }
        }catch(error){
            console.error(error);
            return cartItems;
        }
    }

    return (
        <CartContext.Provider value={{
            cartItems,
            addItemToCart,
            removeItemFromCart,
            clearCart,
            getCartItemCount,
            replaceCartItems,
            getCartItems,
            updateQuantityInCart,
            removeProductFromCart,
        }}>
            {children}
        </CartContext.Provider>
    );
};