import React, {useState, useEffect} from 'react'
import './pricingPage.css'
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import Loader from "react-js-loader";
import SelectOffcuts from './Popups/selectOffcuts';
import { Link } from 'react-router-dom';

import { FaArrowLeftLong, FaArrowRightLong  } from "react-icons/fa6";


import { setBinWithOpenSelectOffcuts, getOrderCosts, setOrderTotalPrice } from '../new_order/orderConfig'
import { set } from 'lodash';

function PricingPage() {
    //  set to show the loader or no
    const [showLoader, setShowLoader] = useState(true);

    //  get all the drawing info when the page loads
    const [binPlacements, setBinPlacements] = useState([]);
    const [offcuts, setOffcuts] = useState([]);
    const [detailsForBins, setDetailsForBins] = useState([]);

    //  API response pricing state
    const [pricingData, setPricingData] = useState(null);

    useEffect(() => {
        //get prices
        async function fetchCosts(){
            setShowLoader(true);
            const pricingResponse = await getOrderCosts();
            setOrderTotalPrice(pricingResponse.orderTotalPrice.toFixed(2), pricingResponse.orderTotalPriceVAT.toFixed(2))
            setPricingData(pricingResponse);
            const newBinPlacements = pricingResponse.bins;
            const newOffcuts = pricingResponse.offcutsArray;
            setBinPlacements(newBinPlacements);
            setOffcuts(newOffcuts);
            console.log(pricingData);
            console.log('UseEffect called for pricing page');
            setShowLoader(false);
        }
        fetchCosts();
    }, []);

    // -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    // -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| DRAWING DISPLAY |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    // -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const smallDrawingPadding = 10;
    //  get the small drawing container size
    const [smallDrawingContainerSize, setSmallDrawingContainerSize] = useState(() => {
        let length = 380 - smallDrawingPadding*2;  // -10 din cauza la paddingul din card care e de 10
        let width = 280 - smallDrawingPadding*2;  // -10 din cauza la paddingul din card care e de 10
        const dimensionsObj = {
            length: length,
            width: width
        };
        return dimensionsObj;
    });

    const [scaledBins, setScaledBins] = useState(null);
    const [scaledCuts, setScaledCuts] = useState(null);
    const [scaledOffcuts, setScaledOffcuts] = useState(null);

    //  calculate scaled bin rectangle, scaled cuts, scaled offcuts  
    useEffect(() => {
        let scaledBinsArray = [];
        let newScaledCuts = [];
        let newScaledOffcuts = [];
        if(binPlacements != null && binPlacements != undefined && offcuts != null && offcuts != undefined && smallDrawingContainerSize != null && smallDrawingContainerSize != undefined){
            console.log('⭐ Pricing page received this bin placements:')
            console.log(binPlacements);
            for(let i = 0; i < binPlacements.length; i++){
                const bin = binPlacements[i];
                //  calculate the scaled bin rectangle dimensions to get the scale percentages
                const fullPanelLength = parseInt(bin.panelLength);
                console.log('Full panel length: ' + fullPanelLength);
                const fullPanelWidth = parseInt(bin.panelWidth);
                let scaledBinLength = null;
                let scaledBinWidth = null;
                if(fullPanelLength > fullPanelWidth){
                    scaledBinLength = smallDrawingContainerSize.length;
                    const differencePercentage = fullPanelWidth / fullPanelLength;
                    scaledBinWidth = scaledBinLength * differencePercentage;
                }else{
                    scaledBinWidth = smallDrawingContainerSize.width;
                    const differencePercentage = fullPanelLength / fullPanelWidth;
                    scaledBinLength = scaledBinWidth * differencePercentage;
                }
                const scaledBinObj = {length: scaledBinLength, width: scaledBinWidth};
                scaledBinsArray = [...scaledBinsArray, scaledBinObj];

                //  calculate the position and dimensions for each scaled cut
                const cutsObj = bin.cuts;
                console.log('Scaled bin length: ' + scaledBinLength);
                console.log('Scaled bin width: ' + scaledBinWidth);
                console.log('Full panel length: ' + fullPanelLength);
                console.log('Full panel width: ' + fullPanelWidth);
                const scalePercentageLength = scaledBinLength / fullPanelLength;
                const scalePercentageWidth = scaledBinWidth / fullPanelWidth;
                console.log('Scale percentage length: ' + scalePercentageLength);
                console.log('Scale percentage width: ' + scalePercentageWidth);
                let newScaledCutsObj = [];
                for(let j = 0; j < cutsObj.length; j++){    //  for each cut individually
                    console.log('Cuts obj:')
                    console.log(cutsObj[j])
                    const newScaledCut = {
                        length: parseInt(cutsObj[j].length) * scalePercentageLength,
                        width: parseInt(cutsObj[j].width) * scalePercentageWidth,
                        x: parseInt(cutsObj[j].x) * scalePercentageLength,
                        y: parseInt(cutsObj[j].y) * scalePercentageWidth,
                    };
                    newScaledCutsObj = [...newScaledCutsObj, newScaledCut];
                }
                newScaledCuts = [...newScaledCuts, newScaledCutsObj];
                console.log('New scaled cuts:')
                console.log(newScaledCuts);

                //  calculate the position and dimensions for each scaled offcut
                let newScaledOffutsObj = [];
                for(let j = 0; j < offcuts.length; j++){
                    const newScaledOffcut = {
                        length: parseInt(offcuts[j].length) * scalePercentageLength,
                        width: parseInt(offcuts[j].width) * scalePercentageWidth,
                        x: parseInt(offcuts[j].x) * scalePercentageLength,
                        y: parseInt(offcuts[j].y) * scalePercentageWidth,
                    };
                    newScaledOffutsObj = [...newScaledOffutsObj, newScaledOffcut];
                }
                newScaledOffcuts = [...newScaledOffcuts, newScaledOffutsObj];
            }
        }
        setScaledBins(scaledBinsArray);
        setScaledCuts(newScaledCuts);
        setScaledOffcuts(newScaledOffcuts);
    }, [binPlacements, offcuts, smallDrawingContainerSize]);

    // select offcuts modal
    const [selectOffcutsModalVisible, setSelectOffcutsModalVisible] = useState(false);

    const openSelectOffcutsModal = (rowID) => {
        setBinWithOpenSelectOffcuts(rowID);
        setSelectOffcutsModalVisible(true);
    };

    const closeSelectOffcutsModal = () => {
        setSelectOffcutsModalVisible(false);
    };

    return (
        <div className='pricingPageSection'>
            {/* POPUPS */}
            {selectOffcutsModalVisible === true ? (
                <SelectOffcuts closeOffcutsPopup={closeSelectOffcutsModal} />
            ) : null}
            {showLoader === true ? (
                <div className='loaderContainer'>
                    <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
                    <p>Calculating prices</p>
                    <p>This can take a couple seconds or a couple minutes depending on your order size.</p>
                </div>
            ) : (
                <div className='pricingaPageContainer'>
                    <p style={{textAlign: 'center', fontSize: '16px', fontWeight: '500'}}>Order summary</p>
                    <div className='pricingPageOrderTotalPriceDiv'>
                        <p style={{fontSize: '18px'}}>Order total: <b>£{pricingData?.orderTotalPrice.toFixed(2)}</b></p>
                        <p style={{fontSize: '16px'}}>Order total with VAT: <b>£{pricingData?.orderTotalPriceVAT.toFixed(2)}</b></p>
                    </div>
                    {binPlacements.length > 0 ? (
                        <div className='category'>
                            <div className='categoryInner'>
                                <div className='categoryTitle'>
                                    <p style={{fontSize: '16px', fontWeight: '500'}}>Sheets to be cut</p>
                                </div>
                                <div className='categoryContent'>
                                    <p style={{fontWeight: '500'}}>Select offcuts</p>
                                    <p>If there are any offcuts, you can mark them here to be included with your order.</p>
                                    <div className='offcutsCarousel'>
                                        {binPlacements.map((bin, index) => (
                                            <div className='card' key={index}>
                                                <p>Plan {index+1}</p>
                                                <div className='cardDrawing'>
                                                    <svg width={smallDrawingContainerSize.length + smallDrawingPadding*2} height={smallDrawingContainerSize.width + smallDrawingPadding*2}>
                                                        <defs>
                                                            <pattern id="dashPattern" patternUnits="userSpaceOnUse" width={10} height={10}>
                                                                <rect x={0} y={0} width="100%" height="100%" fill="hsla(0, 0%, 96%, 1)" />
                                                                <path d="M0 10h20z" strokeWidth={10} stroke="hsla(0, 0%, 100%, 1)" fill="none" />
                                                            </pattern>
                                                        </defs>
                                                        {scaledBins != null && scaledBins.length > 0 && binPlacements.length === scaledOffcuts.length ? (
                                                            <>
                                                                <rect x={((smallDrawingContainerSize.length + smallDrawingPadding*2) - scaledBins[index]?.length)/2} y={((smallDrawingContainerSize.width + smallDrawingPadding*2) - scaledBins[index]?.width)/2} width={scaledBins[index]?.length} height={scaledBins[index]?.width} fill="url(#dashPattern)" stroke={"#247156"} strokeWidth={1}/>
                                                                {scaledOffcuts[index].map((offcut, offcutIndex) => (
                                                                    offcut.length && offcut.width ? (
                                                                        <rect key={`smallScaledOffcut${index}${offcutIndex}`} x={((smallDrawingContainerSize.length + smallDrawingPadding*2) - scaledBins[index]?.length)/2 + offcut.x} y={((smallDrawingContainerSize.width + smallDrawingPadding*2) - scaledBins[index]?.width)/2 + offcut.y} width={offcut.length} height={offcut.width} fill="transparent" stroke={"#247156"} strokeWidth={1}/>
                                                                    ) : null
                                                                ))}
                                                                <rect x={((smallDrawingContainerSize.length + smallDrawingPadding*2) - scaledBins[index]?.length)/2} y={((smallDrawingContainerSize.width + smallDrawingPadding*2) - scaledBins[index]?.width)/2} width={scaledBins[index]?.length} height={scaledBins[index]?.width} fill="transparent" stroke={"#247156"} strokeWidth={1}/>
                                                                {scaledCuts[index].map((cut, cutIndex) => (
                                                                    <rect key={`smallScaledCut${index}${cutIndex}`} x={((smallDrawingContainerSize.length + smallDrawingPadding*2) - scaledBins[index]?.length)/2 + cut.x + 1} y={((smallDrawingContainerSize.width + smallDrawingPadding*2) - scaledBins[index]?.width)/2 + cut.y + 1} width={cut.length} height={cut.width} fill="#99d98c" stroke={"#247156"} strokeWidth={1}/>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </svg>
                                                </div>
                                                <p>{bin.product_code} {bin.decor_name}</p>
                                                <p>{bin.panelLength} x {bin.panelWidth} x {bin.thickness} mm</p>
                                                <div className='selectOffcutsBtn' onClick={() => openSelectOffcutsModal(index)}>
                                                    <p>Select offcuts</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='scrollInfo'>
                                        <FiArrowLeft style={{scale: '1.5'}}/>
                                        <p style={{fontWeight: '600', fontSize: '16px'}}>Scroll</p>
                                        <FiArrowRight style={{scale: '1.5'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    
                    {pricingData?.panelsPricing.length > 0 ? (
                        <div className='category'>
                        <div className='categoryInner'>
                            <div className='categoryTitle'>
                                <p style={{fontSize: '16px', fontWeight: '500'}}>Sheets in order</p>
                                <p style={{fontSize: '16px', fontWeight: '500'}}>£{pricingData?.totalPanelsPricing.toFixed(2)}</p>
                            </div>
                            <div className='categoryContent' style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                <div className='pricingPanelsLabelGrid'>
                                    <div className='pricingPanelsGridItem'>
                                        <p>Product code</p>
                                    </div>
                                    <div className='pricingPanelsGridItem'>
                                        <p>Brand</p>
                                    </div>
                                    <div className='pricingPanelsGridItem'>
                                        <p>Decor name - core</p>
                                    </div>
                                    <div className='pricingPanelsGridItem'>
                                        <p>Sheet size</p>
                                    </div>
                                    <div className='pricingPanelsGridItem'>
                                        <p>Quantity</p>
                                    </div>
                                    <div className='pricingPanelsGridItem'>
                                        <p>Unit price</p>
                                    </div>
                                    <div className='pricingPanelsGridItem'>
                                        <p>Line total</p>
                                    </div>
                                </div>
                                {pricingData?.panelsPricing.map((panel, index) => (
                                    <div className='pricingPanelsGrid' key={index}>
                                        <div className='pricingPanelsGridItem'>
                                            <p>{panel?.product_code}</p>
                                            <p>{panel?.full_sheet === true ? '[Full sheet]' : '[Sheet to be cut]'}</p>
                                        </div>
                                        <div className='pricingPanelsGridItem'>
                                            <p>{panel?.brand}</p>
                                        </div>
                                        <div className='pricingPanelsGridItem'>
                                            <p>{panel?.decor_name} - {panel?.material_type}</p>
                                        </div>
                                        <div className='pricingPanelsGridItem'>
                                            <p>{panel?.sheet_size}</p>
                                        </div>
                                        <div className='pricingPanelsGridItem'>
                                            <p>{panel?.quantity}</p>
                                        </div>
                                        <div className='pricingPanelsGridItem'>
                                            <p>£{panel?.unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingPanelsGridItem'>
                                            <p>£{panel?.row_total.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    ) : null}

                    {pricingData?.edgebandingPricing.length > 0 ? (
                        <div className='category'>
                        <div className='categoryInner'>
                            <div className='categoryTitle'>
                                <p style={{fontSize: '16px', fontWeight: '500'}}>Edgebanding</p>
                                <p style={{fontSize: '16px', fontWeight: '500'}}>£{pricingData?.totalEdgebandingPricing.toFixed(2)}</p>
                            </div>
                            <div className='categoryContent' style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                <div className='pricingEdgebandingLabelGrid'>
                                    <div className='pricingEdgebandingGridItem'>
                                        <p>Edgebanding</p>
                                    </div>
                                    <div className='pricingEdgebandingGridItem'>
                                        <p>Product code</p>
                                    </div>
                                    <div className='pricingEdgebandingGridItem'>
                                        <p>Quantity (min. 10m)</p>
                                    </div>
                                    <div className='pricingEdgebandingGridItem'>
                                        <p>Unit price</p>
                                    </div>
                                    <div className='pricingEdgebandingGridItem'>
                                        <p>Row total</p>
                                    </div>
                                </div>
                                {pricingData?.edgebandingPricing.map((edgebanding, index) => (
                                    <div className='pricingEdgebandingGrid' key={index}>
                                        <div className='pricingEdgebandingGridItem'>
                                            <p>{edgebanding?.description} - {edgebanding?.brand} - {edgebanding?.looks}</p>
                                        </div>
                                        <div className='pricingEdgebandingGridItem'>
                                            <p>{edgebanding?.product_code}</p>
                                        </div>
                                        <div className='pricingEdgebandingGridItem'>
                                            <p>{edgebanding?.quantity.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingEdgebandingGridItem'>
                                            <p>£{edgebanding?.unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingEdgebandingGridItem'>
                                            <p>£{edgebanding?.total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    ) : null}


                    {pricingData?.angleCutsPricing.unit_price !== null || pricingData?.hingeDrillingPricing.unit_price !== null || pricingData?.shelfDrillingPricing.unit_price !== null ||  
                        pricingData?.bondingPricing.under05sqm_unit_price !== null || pricingData?.bondingPricing.under1sqm_unit_price !== null || pricingData?.bondingPricing.under2sqm_unit_price !== null ||
                        pricingData?.bondingPricing.under3sqm_unit_price !== null || pricingData?.bondingPricing.over3sqm_unit_price !== null ? (
                        <div className='category'>
                        <div className='categoryInner'>
                            <div className='categoryTitle'>
                                <p style={{fontSize: '16px', fontWeight: '500'}}>Additional services</p>
                                <p style={{fontSize: '16px', fontWeight: '500'}}>£{pricingData?.totalServicesPricing.toFixed(2)}</p>
                            </div>
                            <div className='categoryContent' style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                <div className='pricingServicesLabelGrid'>
                                    <div className='pricingServicesGridItem'>
                                        <p>Service</p>
                                    </div>
                                    <div className='pricingServicesGridItem'>
                                        <p>Short description</p>
                                    </div>
                                    <div className='pricingServicesGridItem'>
                                        <p>Quantity</p>
                                    </div>
                                    <div className='pricingServicesGridItem'>
                                        <p>Unit price</p>
                                    </div>
                                    <div className='pricingServicesGridItem'>
                                        <p>Row total</p>
                                    </div>
                                </div>
                                {pricingData?.angleCutsPricing.unit_price !== null ? (
                                    <div className='pricingServicesGrid'>
                                        <div className='pricingServicesGridItem'>
                                            <p>Cutting of panel diagonally</p>
                                            <p>(Angle cut)</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>Cutting of panel diagonally based on customer requirements - charged per single cut.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>{pricingData?.angleCutsPricing.quantity}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.angleCutsPricing.unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.angleCutsPricing.total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ) : null}
                                {pricingData?.hingeDrillingPricing.unit_price !== null && pricingData?.hingeDrillingPricing.screwOn_quantity !== 0 || pricingData?.hingeDrillingPricing.inserta_quantity !== 0 ? (
                                    <div className='pricingServicesGrid'>
                                        <div className='pricingServicesGridItem'>
                                            <p>Drilling - 35mm hinge cup hole</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>Concealed 35mm hinge cup hole drilling.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>{parseInt(pricingData?.hingeDrillingPricing.screwOn_quantity) + parseInt(pricingData?.hingeDrillingPricing.inserta_quantity)}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.hingeDrillingPricing.unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{parseFloat(pricingData?.hingeDrillingPricing.screwOn_total_price.toFixed(2)) + parseFloat(pricingData?.hingeDrillingPricing.inserta_total_price.toFixed(2))}</p>
                                        </div>
                                    </div>
                                ) : null}
                                {pricingData?.shelfDrillingPricing.unit_price !== null && pricingData?.shelfDrillingPricing.mm5_quantity !== 0 || pricingData?.shelfDrillingPricing.mm75_quantity !== 0 ? (
                                    <div className='pricingServicesGrid'>
                                        <div className='pricingServicesGridItem'>
                                            <p>Drilling of holes on one side</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>Adjustable shelf hole drilling on one side - charged per hole pair.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>{parseInt(pricingData?.shelfDrillingPricing.mm5_quantity) + parseInt(pricingData?.shelfDrillingPricing.mm75_quantity)}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.shelfDrillingPricing.unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{parseFloat(pricingData?.shelfDrillingPricing.mm5_total_price.toFixed(2)) + parseFloat(pricingData?.shelfDrillingPricing.mm75_total_price.toFixed(2))}</p>
                                        </div>
                                    </div>
                                ) : null}
                                {pricingData?.bondingPricing.under05sqm_unit_price !== null ? (
                                    <div className='pricingServicesGrid'>
                                        <div className='pricingServicesGridItem'>
                                            <p>Bonding of panel up to 0.5 sq. m.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>Bonding of two panels in order to create a thicker panel. Price level of panel bonding up to 0.5 m2 in size.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>{pricingData?.bondingPricing.under05sqm_quantity}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.bondingPricing.under05sqm_unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.bondingPricing.under05sqm_total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ) : null}
                                {pricingData?.bondingPricing.under1sqm_unit_price !== null ? (
                                    <div className='pricingServicesGrid'>
                                        <div className='pricingServicesGridItem'>
                                            <p>Bonding of panel up to 1 sq. m.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>Bonding of two panels in order to create a thicker panel. Price level of panel bonding up to 1 m2 in size.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>{pricingData?.bondingPricing.under1sqm_quantity}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.bondingPricing.under1sqm_unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.bondingPricing.under1sqm_total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ) : null}
                                {pricingData?.bondingPricing.under2sqm_unit_price !== null ? (
                                    <div className='pricingServicesGrid'>
                                        <div className='pricingServicesGridItem'>
                                            <p>Bonding of panel up to 2 sq. m.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>Bonding of two panels in order to create a thicker panel. Price level of panel bonding up to 2 m2 in size.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>{pricingData?.bondingPricing.under2sqm_quantity}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.bondingPricing.under2sqm_unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.bondingPricing.under2sqm_total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ) : null}
                                {pricingData?.bondingPricing.under3sqm_unit_price !== null ? (
                                    <div className='pricingServicesGrid'>
                                        <div className='pricingServicesGridItem'>
                                            <p>Bonding of panel up to 3 sq. m.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>Bonding of two panels in order to create a thicker panel. Price level of panel bonding up to 3 m2 in size.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>{pricingData?.bondingPricing.under3sqm_quantity}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.bondingPricing.under3sqm_unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.bondingPricing.under3sqm_total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ) : null}
                                {pricingData?.bondingPricing.over3sqm_unit_price !== null ? (
                                    <div className='pricingServicesGrid'>
                                        <div className='pricingServicesGridItem'>
                                            <p>Bonding of panel over 3 sq. m.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>Bonding of two panels in order to create a thicker panel. Price level of panel bonding over 3 m2 in size.</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>{pricingData?.bondingPricing.over3sqm_quantity}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.bondingPricing.over3sqm_unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingServicesGridItem'>
                                            <p>£{pricingData?.bondingPricing.over3sqm_total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    ) : null}


                    {pricingData?.sprayCoatingPricing.whitePrimer_unit_price !== null || pricingData?.sprayCoatingPricing.solidColorSatin_unit_price !== null || 
                        pricingData?.sprayCoatingPricing.solidColorMatt_unit_price !== null || pricingData?.sprayCoatingPricing.clearSealant_unit_price !== null ||
                        pricingData?.sprayCoatingPricing.clearLacSatin_unit_price !== null || pricingData?.sprayCoatingPricing.clearLacMatt_unit_price !== null ? (
                        <div className='category'>
                        <div className='categoryInner'>
                            <div className='categoryTitle'>
                                <p style={{fontSize: '16px', fontWeight: '500'}}>Spray coating</p>
                                <p style={{fontSize: '16px', fontWeight: '500'}}>£{pricingData?.totalSprayCoatingPricing?.toFixed(2)}</p>
                            </div>
                            <div className='categoryContent' style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                <div className='pricingSprayCoatingLabelGrid'>
                                    <div className='pricingSprayCoatingGridItem'>
                                        <p>Spray coating type</p>
                                    </div>
                                    <div className='pricingSprayCoatingGridItem'>
                                        <p>Quantity [sq.m.]</p>
                                    </div>
                                    <div className='pricingSprayCoatingGridItem'>
                                        <p>Unit price</p>
                                    </div>
                                    <div className='pricingSprayCoatingGridItem'>
                                        <p>Row total</p>
                                    </div>
                                </div>
                                {pricingData?.sprayCoatingPricing.whitePrimer_unit_price !== null ? (
                                    <div className='pricingSprayCoatingGrid'>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>White primer spray coating - charged per panel face.</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>{pricingData?.sprayCoatingPricing.whitePrimer_quantity}</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>£{pricingData?.sprayCoatingPricing.whitePrimer_unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>£{pricingData?.sprayCoatingPricing.whitePrimer_total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ) : null}
                                {pricingData?.sprayCoatingPricing.solidColorSatin_unit_price !== null ? (
                                    <div className='pricingSprayCoatingGrid'>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>Solid colour satin spray coating - charged per panel face.</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>{pricingData?.sprayCoatingPricing.solidColorSatin_quantity}</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>£{pricingData?.sprayCoatingPricing.solidColorSatin_unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>£{pricingData?.sprayCoatingPricing.solidColorSatin_total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ) : null}
                                {pricingData?.sprayCoatingPricing.solidColorMatt_unit_price !== null ? (
                                    <div className='pricingSprayCoatingGrid'>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>Solid colour matt spray coating - charged per panel face.</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>{pricingData?.sprayCoatingPricing.solidColorMatt_quantity}</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>£{pricingData?.sprayCoatingPricing.solidColorMatt_unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>£{pricingData?.sprayCoatingPricing.solidColorMatt_total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ) : null}
                                {pricingData?.sprayCoatingPricing.clearLacSatin_unit_price !== null ? (
                                    <div className='pricingSprayCoatingGrid'>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>Clear lacquer satin spray coating - charged per panel face.</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>{pricingData?.sprayCoatingPricing.clearLacSatin_quantity}</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>£{pricingData?.sprayCoatingPricing.clearLacSatin_unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>£{pricingData?.sprayCoatingPricing.clearLacSatin_total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ) : null}
                                {pricingData?.sprayCoatingPricing.clearLacMatt_unit_price !== null ? (
                                    <div className='pricingSprayCoatingGrid'>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>Clear lacquer matt spray coating - charged per panel face.</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>{pricingData?.sprayCoatingPricing.clearLacMatt_quantity}</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>£{pricingData?.sprayCoatingPricing.clearLacMatt_unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>£{pricingData?.sprayCoatingPricing.clearLacMatt_total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ) : null}
                                {pricingData?.sprayCoatingPricing.clearSealant_unit_price !== null ? (
                                    <div className='pricingSprayCoatingGrid'>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>Clear sealant spray coating - charged per panel face.</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>{pricingData?.sprayCoatingPricing.clearSealant_quantity}</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>£{pricingData?.sprayCoatingPricing.clearSealant_unit_price.toFixed(2)}</p>
                                        </div>
                                        <div className='pricingSprayCoatingGridItem'>
                                            <p>£{pricingData?.sprayCoatingPricing.clearSealant_total_price.toFixed(2)}</p>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    ) : null}

                <div className='pricingPageBottomBtnsDiv'>
                    <Link to={'/neworder'} className='pricingPageBackBtn'>
                        <FaArrowLeftLong />
                        <p>Back</p>
                    </Link>
                    <Link to={'/deliveryinfo'} className='pricingPageContinueBtn'>
                        <p>Continue to delivery information</p>
                        <FaArrowRightLong />
                    </Link>
                </div>
                </div>
            )}
        </div>
    )
}

export default PricingPage