import React, { useState, useEffect } from 'react'
import './comandaPopup.css';
import { getPopupData } from './comanda_popup_data';
import { GoDot } from "react-icons/go";

function ComandaPopup(props) {

    const [delivery_info, setDeliveryInfo] = useState(null);
    const [products, setProducts] = useState([]);
    const [order_price, setOrderPrice] = useState(0);
    const [order_date, setOrderDate] = useState('');
    const [order_id, setOrderId] = useState(null);

    const fetchPopupData = async() =>{
        const popup_data = await getPopupData();
        console.log(popup_data);
        setDeliveryInfo(popup_data.delivery_info)
        setProducts(popup_data.products);
        setOrderPrice(popup_data.price);
        setOrderDate(popup_data.date);
        setOrderId(popup_data.order_id);
    }

    useEffect(() =>{
        fetchPopupData();
    }, []);

    const closePopup = () => {
        props.closePopup();
    }

    return (
        <div className='page'>
            <div style={{background: 'red', color: 'white', width: 'fit-content', padding: '5px', borderRadius: '5px', cursor: 'pointer'}} onClick={closePopup}>
                <p>Close</p>
            </div>
            <div className={'header'}>
                <img src='\res\images\myBoardsLogoShadow.png' style={{width: '150px', height: 'auto'}} alt='logo'/>
                <p style={{fontSize: '16px'}}>ORDER SUMMARY</p>
            </div>
            <div className={'orderDatap'}>
                <p className={'orderDatap'}>Order ID: {order_id}</p>
                <p className={'orderDatap'}>Order date: {order_date}</p>
                <p className={'orderDatap'}>Price (with VAT): £{order_price.toFixed(2)}</p>

                <p style={{fontSize: '12px', fontWeight: '500', marginTop: '25px'}}>Delivery info:</p>
                <p className={'orderDeliverySectionp'}>Delivery or Pickup: {delivery_info?.delivery_type === 'delivery' ? 'Delivery' : 'Pickup'}</p>
                {delivery_info?.delivery_type === 'delivery' ? (
                    <>
                        <p className={'orderDeliverySectionp'}>Name: {delivery_info?.name}</p>
                        <p className={'orderDeliverySectionp'}>Email: {delivery_info?.email}</p>
                        <p className={'orderDeliverySectionp'}>Address: {delivery_info?.address}</p>
                        <p className={'orderDeliverySectionp'}>City: {delivery_info?.city}</p>
                        <p className={'orderDeliverySectionp'}>Postcode: {delivery_info?.postcode}</p>
                        <p className={'orderDeliverySectionp'}>Phone number: {delivery_info?.phone}</p>
                        <p className={'orderDeliverySectionp'}>Order preferences: {delivery_info?.order_preferences}</p>
                    </>
                ) : (
                    <>
                        <p className={'orderDeliverySectionp'}>Name: {delivery_info?.name}</p>
                        <p className={'orderDeliverySectionp'}>Email: {delivery_info?.email}</p>
                        <p className={'orderDeliverySectionp'}>Phone number: {delivery_info?.phone}</p>
                        <p className={'orderDeliverySectionp'}>Order preferences: {delivery_info?.order_preferences !== '' ? delivery_info?.order_preferences : '-'}</p>
                    </>
                )}
            </div>
            <div className={'orderContent'}>
                <div className='orderProductsListContainer'>
                    <p style={{fontSize: '14px'}}>Order products</p>
                    <div className='orderProductsList'>
                        {products.map((product, index) => (
                            <div className='orderProductsListItem' key={index}>
                                <div className='orderProductsListItemInfo'>
                                    <p className='orderProductsListItemName'>{index + 1}. {product.productName}</p>
                                    <div className='orderProductsItemConfig'>
                                        {product.baseOptions.length > 0 && product.baseOptions.map((option, optionIndex) => (
                                            <div key={optionIndex} className='cart-item-configuration-element'>
                                                <GoDot className='cart-item-configuration-element-icon' />
                                                <p>{option.name}: {option.value}</p>
                                            </div>
                                        ))}
                                        {product.squareMeterFixedOptions.length > 0 && product.squareMeterFixedOptions.map((option, optionIndex) => (
                                            <div key={optionIndex} className='cart-item-configuration-element'>
                                                <GoDot className='cart-item-configuration-element-icon' />
                                                <p>{option.name}: {option.value}</p>
                                            </div>
                                        ))}
                                        {product.squareMeterRangeOptions.length > 0 && product.squareMeterRangeOptions.map((option, optionIndex) => (
                                            <div key={optionIndex} className='cart-item-configuration-element'>
                                                <GoDot className='cart-item-configuration-element-icon' />
                                                <p>{option.name}: {option.value}mm</p>
                                            </div>
                                        ))}
                                        {product.fixedPercentageOptions.length > 0 && product.fixedPercentageOptions.map((option, optionIndex) => (
                                            <div key={optionIndex} className='cart-item-configuration-element'>
                                                <GoDot className='cart-item-configuration-element-icon' />
                                                <p>{option.name}: {option.value}</p>
                                            </div>
                                        ))}
                                        {product.rangePercentageOptions.length > 0 && product.rangePercentageOptions.map((option, optionIndex) => (
                                            <div key={optionIndex} className='cart-item-configuration-element'>
                                                <GoDot className='cart-item-configuration-element-icon' />
                                                <p>{option.name}: {option.value}mm</p>
                                            </div>
                                        ))}
                                        {product.additionalOptions.length > 0 && product.additionalOptions.map((option, optionIndex) => (
                                            <div key={optionIndex} className='cart-item-configuration-element'>
                                                <GoDot className='cart-item-configuration-element-icon' />
                                                <p>{option.name}: {option.value}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <p style={{fontSize: '14px', fontWeight: 400}}>Qty: {product.quantity}</p>
                                    <p className='orderShopProductsListItemPrice'>£{parseFloat(product.price).toFixed(2)}</p>
                                </div>
                            </div>
                        ))} 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComandaPopup