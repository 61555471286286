import React, { useState, useEffect, useRef } from 'react';
import './shopPage.css'
import Header from '../header/header';
import { FiSearch } from "react-icons/fi";
import autoAnimate from '@formkit/auto-animate';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { APIurl } from '../../ApiService';
import { PiSmileySadLight } from "react-icons/pi";


const ShopPage = () => {

    const navigate = useNavigate();
    const { urlcategory } = useParams();
    const cookies = new Cookies();
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [categoryName, setCategoryName] = useState('');

    // Function to fetch categories
    const fetchCategoriesAndProducts = async () => {
        try {
            const req_data = {
                category_id: urlcategory,
            };
            const response = await axios.post(`${APIurl}/api/getfilteredproducts`, req_data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const responseStatus = response.data.status;
            const dataMessage = response.data.data;

            if (responseStatus !== 'error') {
                if (dataMessage === 'Categories not found') {
                    fetchCategoriesAndProducts();
                    setCategoryName('');
                }else if (dataMessage === 'Products not found') {
                    const response_data = response.data.response_data;
                    setProducts([]);
                    setFilteredProducts([]);
                    setCategories(response_data.categories);
                    setCategoryName(response_data.category_name);
                    setMobileCategorySelectValue({
                        name: response_data.category_name,
                        id: urlcategory
                    })
                }else{
                    const response_data = response.data.response_data;
                    setProducts(response_data.products);
                    setFilteredProducts(response_data.products);
                    setCategories(response_data.categories);
                    setCategoryName(response_data.category_name);
                    setMobileCategorySelectValue({
                        name: response_data.category_name,
                        id: urlcategory
                    })
                }
            }
        } catch (error) {
            console.error("Error fetching product categories:", error);
        }
    };
    useEffect(() => {
        fetchCategoriesAndProducts();
        console.log('urlcategory:');
        console.log(urlcategory);
    }, [urlcategory]);

    // Handle search text change
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    // Search and filter products based on search text
    const searchProducts = async () => {
        let newFilteredProducts = [...products];

        // Filter by searchText if it's not empty
        if (searchText.trim() !== '') {
            const lowerCaseSearchText = searchText.trim().toLowerCase();
            newFilteredProducts = newFilteredProducts.filter(product =>
                product.name.toLowerCase().includes(lowerCaseSearchText)
            );
        }

        setFilteredProducts(newFilteredProducts);
    };

    const redirectToCategory = (categoryID) => {
        navigate(`/shop/${categoryID}`);
    }

    const productsListRef = useRef(null);
    useEffect(() => {
        if (productsListRef.current) {
            autoAnimate(productsListRef.current);
        }
    }, []);
    
    const redirectToProduct = (productID) => {
        navigate(`/shop/product/${productID}`);
    }

    const placeholderImage = process.env.PUBLIC_URL + '/res/images/placeholder.jpg';

    const [mobileCategorySelectValue, setMobileCategorySelectValue] = useState({
        name: '',
        id: null
    });
    const handleSelectChange = (event) => {
        const selectedCategoryId = event.target.value; // This will be the category id
        console.log('selected category:');
        console.log(selectedCategoryId);
        
        // Find the selected category object from the categories array
        redirectToCategory(selectedCategoryId);
    };

    return (
        <div className='shop-section'>
            <Header />
            <div className='shop-hero-container'>
                <p>Browse and shop for the best quality materials for your project</p>
            </div>
            <div className='shop-container'>
                <div className='shop-categories-list'>
                    <p style={{fontWeight: '500'}}>Shop for:</p>
                    {categories?.map((category, index) => (
                        <div key={'category' + index} className={category.name === categoryName ? 'shop-category-selected' : 'shop-category'} onClick={() => redirectToCategory(category.id)}>
                            <p>{category.name}</p>
                        </div>
                    ))}
                    
                </div>

                <div className='shop-products-container'>
                    {urlcategory !== undefined ? (
                        <div className='shop-products-header'>
                            <p style={{fontWeight: '500'}} className='shop-category-label'>{categoryName !== '' ? categoryName : 'Browse for products'}</p>
                            <select
                                id="dropdown"
                                value={mobileCategorySelectValue.id} // Should correspond to the selected category's ID
                                onChange={handleSelectChange}
                                className="mobileShopCategorySelect"
                                >
                                <option value="" disabled>Select a category</option> {/* Optional placeholder */}
                                {categories?.map((category, index) => (
                                    <option
                                    key={`category${index}`}
                                    value={category.id} // Use the category ID as the value
                                    className={category.name === categoryName ? 'shop-category-selected' : 'shop-category'}
                                    >
                                    {category.name} {/* Option text cannot have block elements like <p> */}
                                    </option>
                                ))}
                            </select>
                            <div className='shop-products-header-btns'>
                                <div className='shop-products-header-search-field'>
                                    <FiSearch className='shop-products-header-search-icon'/>
                                    <input type="text" placeholder="Search products" onChange={handleSearchChange} value={searchText}/>
                                </div>
                                <div className='shop-products-header-search-btn' onClick={searchProducts}>
                                    <p>Search</p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className='shop-products-list' ref={productsListRef}>

                        {urlcategory !== undefined && filteredProducts.length > 0 ? 
                            (filteredProducts.map((product, index) => (
                                <div key={'produse-container-list-item' + index} className='shop-products-list-item' onClick={() => redirectToProduct(product.id)}>
                                    <div className='shop-products-list-item-image'>
                                        <img src={product.image !== null ? product.image : process.env.PUBLIC_URL + '/res/images/placeholder.jpg'} 
                                            alt={product.name}
                                            onError={(e) => {
                                                e.target.onerror = null; // Prevents looping
                                                e.target.src = placeholderImage;
                                            }}
                                        />
                                    </div>
                                    <div className='shop-products-list-item-info-body'>
                                        <div className='produse-container-list-item-info'>
                                            <p className='produse-container-list-item-info-code'>{product.category}</p>
                                            <p className='produse-container-list-item-info-title'>{product.name}</p>
                                            <p className='produse-container-list-item-info-code'>{product.stock ? 'In Stock' : 'Out of Stock'}</p>
                                            <p className='produse-container-list-item-info-price'>£{product.price.toFixed(2)}</p>
                                        </div>
                                        <div className='shop-products-list-item-btn-container'>
                                            <div className='shop-products-list-item-buy-btn'>
                                                <p>Configure</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            ) : (
                                urlcategory !== undefined ? (
                                    <div className='shop-products-no-products'>
                                        <PiSmileySadLight className='shop-products-no-products-icon' />
                                        <p>No products found...</p>
                                    </div>
                                ) : (
                                    categories.length > 0 && categories.map((category, index) => (
                                        <div key={'category' + index} className='shop-products-category-showcase-item' onClick={() => redirectToCategory(category.id)}>
                                            <div className='shop-products-category-showcase-item-image'>
                                                <img src={category.image !== null ? category.image : process.env.PUBLIC_URL + '/res/images/placeholder.jpg'}
                                                    alt={category.name}
                                                    onError={(e) => {
                                                        e.target.onerror = null; // Prevents looping
                                                        e.target.src = placeholderImage;
                                                    }}
                                                />
                                            </div>
                                            <p>Browse {category.name}</p>
                                        </div>
                                    ))
                                )
                            )
                        }                        

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShopPage