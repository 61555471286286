import React, { useState, useEffect } from 'react'
import './ordersHistory.css'
import Header from '../header/header'
import { PiSmileySad } from "react-icons/pi";
import { IoIosAdd, IoIosSearch } from "react-icons/io";
import { FaShop } from "react-icons/fa6";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { Link, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import Loader from "react-js-loader";

import PdfOrderSumary from '../pdfOrderSummary/pdfOrderSumary';
import { PDFDownloadLink } from '@react-pdf/renderer';


import { APIurl } from '../../ApiService';
import { set } from 'lodash';


function OrdersHistory() {

    const cookies = new Cookies();
    const navigate = useNavigate();

    const [ordersHistoryList, setOrdersHistoryList] = useState([]);
    const [shopOrdersHistoryList, setShopOrdersHistoryList] = useState([]);
    const [showLoader, setShowLoader] = useState(true);

    const fetchOrders = async(userToken) =>{
        setShowLoader(true);
        const tokenData = {
            token: userToken
        };
        try {
            const response = await axios.post(`${APIurl}/api/ordershistory`, tokenData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
    
            if (responseStatus === 'error') {
                setShowLoader(false);
                navigate('/neworder');
                return;
            } else {
                if(dataMessage === 'Invalid token'){
                    navigate('/login');
                }else{
                    const orders = response.data.ordersList;
                    setOrdersHistoryList(orders);
                    setShowLoader(false);
                    return;
                }
            }
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle or log the error
            setShowLoader(false);
            navigate('/neworder');
        }
    }

    const fetchShopOrders = async(userToken) =>{
        setShowLoader(true);
        const tokenData = {
            token: userToken
        };
        try {
            const response = await axios.post(`${APIurl}/api/shopordershistory`, tokenData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
    
            if (responseStatus === 'error') {
                setShowLoader(false);
                navigate('/neworder');
                return;
            } else {
                if(dataMessage === 'Invalid token'){
                    navigate('/login');
                }else{
                    const orders = response.data.ordersList;
                    orders.forEach(order => {
                        order.delivery_info = JSON.parse(order.delivery_info);
                        order.products = JSON.parse(order.products);
                        order.products = order.products.map(product => {
                            // Combine all option arrays into a single configuration array
                            const configuration = [
                                ...product.baseOptions.map(option => ({ optionName: option.name, value: option.value })),
                                ...product.squareMeterFixedOptions.map(option => ({ optionName: option.name, value: option.value })),
                                ...product.squareMeterRangeOptions.map(option => ({ optionName: option.name, value: option.value })),
                                ...product.fixedPercentageOptions.map(option => ({ optionName: option.name, value: option.value })),
                                ...product.rangePercentageOptions.map(option => ({ optionName: option.name, value: option.value })),
                                ...product.additionalOptions.map(option => ({ optionName: option.name, value: option.value }))
                            ];
                        
                            // Return the new product object with the configuration added
                            return {
                                ...product,
                                configuration
                            };
                        });
                        order.showSummary = false;
                    });
                    

                    console.log(orders);
                    setShopOrdersHistoryList(orders);
                    setShowLoader(false);
                    return;
                }
            }
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle or log the error
            setShowLoader(false);
            navigate('/neworder');
        }
    }

    useEffect(() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            // get account details
            fetchOrders(savedToken);
            fetchShopOrders(savedToken);
        }else{
            console.log('You must be logged in to access this page!');
            navigate('/login');
        }
    }, []);

    function formatPrice(price) {
        const priceNumber = parseFloat(price);
        return priceNumber.toLocaleString('en-GB', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const displayOrderStatus = (status, checkout_status) =>{
        if(status === 'unpaid' && checkout_status === 'complete'){
            return 'Processing';
        }else if(status === 'paid'){
            return 'Paid';
        }
    }

    function formatNumberToSixDigits(number) {
        // Convert the number to a string
        let numberString = String(number);
        
        // Pad the string with leading zeros to make it 6 characters long
        while (numberString.length < 6) {
            numberString = '0' + numberString;
        }

        let formattedID = 'C' + numberString;
        
        return formattedID;
    }

    function formatShopNumberToSixDigits(number) {
        // Convert the number to a string
        let numberString = String(number);
        
        // Pad the string with leading zeros to make it 6 characters long
        while (numberString.length < 6) {
            numberString = '0' + numberString;
        }
        let formattedID = 'S' + numberString;
        return formattedID;
    }

    const toggleShopOrderSummary = (orderIndex) =>{
        const newOrdersHistoryList = [...shopOrdersHistoryList];
        newOrdersHistoryList[orderIndex].showSummary = !newOrdersHistoryList[orderIndex].showSummary;
        setShopOrdersHistoryList(newOrdersHistoryList);
    }

    return (
        <div className='historySection'>
            <Header />
            {showLoader === true ? (
                <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
            ) : (
                ordersHistoryList.length > 0 || shopOrdersHistoryList.length > 0 ? (
                    <div className='ordersHistoryListContainer'>
                        <div className='historyNrOrdersFoundDiv'>
                            <IoIosSearch />
                            <p>You have made {ordersHistoryList.length + shopOrdersHistoryList.length} orders:</p>
                        </div>
                        {ordersHistoryList.map((order, index) => (
                            <div className='ordersHistoryListItem' key={index}>
                                <p>Order ID: <b>{formatNumberToSixDigits(order.id)}</b></p>
                                <p>Date: <b>{order.date}</b></p>
                                <p>Price (With VAT): <b>£{formatPrice(order.price)}</b></p>
                                <PDFDownloadLink document={<PdfOrderSumary
                                    order_pricing = {JSON.parse(order.order_pricing)}
                                    order_id = {formatNumberToSixDigits(order.id)}
                                    order_date = {order.date}
                                    order_price = {formatPrice(order.price)}
                                    delivery_info = {JSON.parse(order.delivery_info)}
                                    offcuts_selections = {JSON.parse(order.offcuts_selections)}
                                />} fileName={`MyBoards order summary ${order.date}`} className='ordersHistoryDownloadBtn'>
                                    <FiDownload />
                                    <p>Download order summary</p>
                                </PDFDownloadLink>
                            </div>
                        ))}
                        {shopOrdersHistoryList.map((order, index) => (
                            <div className='ordersShopHistoryListItemContainer' key={index}>
                                <div className='ordersShopHistoryListItem'>
                                    <p>Order ID: <b>{formatShopNumberToSixDigits(order.id)}</b></p>
                                    <p>Date: <b>{order.date}</b></p>
                                    <p>Price (With VAT): <b>£{formatPrice(order.price)}</b></p>
                                    <div className='ordersHistoryDownloadBtn' onClick={() => toggleShopOrderSummary(index)}>
                                        {order.showSummary === true ? <FaRegEyeSlash /> : <FaRegEye />}
                                        <p>{order.showSummary === true ? 'Hide' : 'Show'} order summary</p>
                                    </div>
                                </div>
                                {order.showSummary === true ? (
                                    <div className='ordersShopHistoryDetailsSection'>
                                        {order.products.map((product, productIndex) => (
                                            <div key={'productIndex' + productIndex} className='ordersShopHistoryProduct'>
                                                <p style={{fontWeight: '500'}}>{productIndex + 1}. {product.productName}</p>
                                                {product.configuration.map((option, optionIndex) => (
                                                    <div key={'optionIndex' + optionIndex} className='ordersShopHistoryProductOption'>
                                                        <p>• {option.optionName}: {option.value}</p>
                                                    </div>
                                                ))}
                                                <p>Qty: {product.quantity}</p>
                                                <p>Price: £{product.price}</p>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='noHistoryDiv'>
                        <PiSmileySad style={{width: '35px', height: '35px', color: '#6ab29b'}}/>
                        <p>You haven't made any orders yet.</p>
                        <Link to={'/neworder'} className='historyNewOrderBtn'>
                            <IoIosAdd style={{width: '30px', height: '30px'}}/>
                            <p>Make an order</p>
                        </Link>
                        <Link to={'/shop'} className='historyNewOrderBtn'>
                            <FaShop style={{width: '30px', height: '30px'}}/>
                            <p>Browse shop</p>
                        </Link>
                    </div>
                )
            )}
        </div>
    );
    
}

export default OrdersHistory