import React, { useState, useEffect } from 'react'
import './comenziMaga.css'
import { IoIosSearch } from "react-icons/io";
import Sidebar from '../sidebar/sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import Loader from "react-js-loader";
import { FaEye } from "react-icons/fa";
import ComandaPopup from './comandaPopup';
import { setNewPopupData } from './comanda_popup_data';


import { APIurl } from '../../ApiService';

function ComenziMaga() {

    const [hidePage, setHidePage] = useState(true);

    const cookies = new Cookies();
    const navigate = useNavigate();

    const [fetched_paid_orders, setFetchedPaidOrders] = useState([]);
    const [fetched_processing_orders, setFetchedProcessingOrders] = useState([]);
    const [fetched_unpaid_orders, setFetchedUnpaidOrders] = useState([]);
    const [displayList, setDisplayList] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [ordersCategory, setOrdersCategory] = useState('paid');
    const [search_bar_input, setSearchBarInput] = useState('');

    //  get the orders info
    const fetchOrders = async(userToken) =>{
        setShowLoader(true);
        const tokenData = {
            token: userToken
        };
        try {
            const response = await axios.post(`${APIurl}/api/adminshoporders`, tokenData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
    
            if (responseStatus === 'error') {
                setShowLoader(false);
                return;
            } else {
                if(dataMessage === 'Not admin'){
                    navigate('/login');
                }else{
                    setHidePage(false);
                    console.log(response.data);
                    const paid_orders = response.data.paid_orders;
                    const processing_orders = response.data.processing_orders;
                    const unpaid_orders = response.data.unpaid_orders;
                    paid_orders.forEach(order => {
                        order.delivery_info = JSON.parse(order.delivery_info);
                    });
                    processing_orders.forEach(order => {
                        order.delivery_info = JSON.parse(order.delivery_info);
                    });
                    unpaid_orders.forEach(order => {
                        order.delivery_info = JSON.parse(order.delivery_info);
                    });
                    setFetchedPaidOrders(paid_orders);
                    setFetchedProcessingOrders(processing_orders);
                    setFetchedUnpaidOrders(unpaid_orders);
                    setShowLoader(false);
                    return;
                }
            }
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle or log the error
            setShowLoader(false);
            navigate('/login');
        }
    }
    useEffect(() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            // get account details
            fetchOrders(savedToken);
        }else{
            navigate('/login');
        }
    }, []);

    const changeOrdersCategory = (ctgName) => {
        setOrdersCategory(ctgName);
    }

    const handleSearchBarInputChange = (event) =>{
        const input_value = event.target.value;
        setSearchBarInput(input_value);
    }

    useEffect(() => {
        filterDisplayList();
    }, [ordersCategory, search_bar_input, fetched_paid_orders, fetched_processing_orders. fetched_unpaid_orders]);

    const filterDisplayList = () =>{
        let filtered_list = [];
        if(ordersCategory === 'paid'){
            if(search_bar_input !== '' && search_bar_input !== null){
                filtered_list = fetched_paid_orders.filter(order => {
                    const order_id_matches = order.id.toString().includes(search_bar_input.toLowerCase());
                    const email_matches = order.delivery_info.email.toLowerCase().includes(search_bar_input.toLowerCase());   
                    // Return true if either product_code or color_description matches the searchQuery
                    return (order_id_matches || email_matches);
                });
            }else{
                filtered_list = fetched_paid_orders;
            }
            setDisplayList(filtered_list);
        }else if(ordersCategory === 'processing'){
            if(search_bar_input !== '' && search_bar_input !== null){
                filtered_list = fetched_processing_orders.filter(order => {
                    const order_id_matches = order.id.toString().includes(search_bar_input.toLowerCase());
                    const email_matches = order.delivery_info.email.toLowerCase().includes(search_bar_input.toLowerCase());   
                    // Return true if either product_code or color_description matches the searchQuery
                    return (order_id_matches || email_matches);
                });
            }else{
                filtered_list = fetched_processing_orders;
            }
            setDisplayList(filtered_list);
        }else if(ordersCategory === 'unpaid'){
            if(search_bar_input !== '' && search_bar_input !== null){
                filtered_list = fetched_unpaid_orders.filter(order => {
                    const order_id_matches = order.id.toString().includes(search_bar_input.toLowerCase());
                    const email_matches = order.delivery_info.email.toLowerCase().includes(search_bar_input.toLowerCase());   
                    // Return true if either product_code or color_description matches the searchQuery
                    return (order_id_matches || email_matches);
                });
            }else{
                filtered_list = fetched_unpaid_orders;
            }
            setDisplayList(filtered_list);
        }
    }

    const [summaryPopupVisible, setSummaryPopupVisible] = useState(false);
    const openSummaryPopup = async(order, order_id) =>{
        setNewPopupData(order, order_id);
        setSummaryPopupVisible(true);
    }
    const closeSummaryPopup = () =>{
        setSummaryPopupVisible(false);
    }

    return (
        hidePage == true ? null : (
            <div>
                {summaryPopupVisible === true ? (
                    <ComandaPopup  closePopup={closeSummaryPopup}/>
                ) : (
                    <div className='adminPage'>
                        <Sidebar activeTab={'comenzi_magazin'}/>
                        <div className='comenziSection'>
                            {showLoader === true ? (
                                <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
                            ) : (
                                <>
                                    <div className='comenziHeader'>
                                        <p className='comenziHeaderTitle'>Comenzi magazin 📈</p>
                                        <div className='comenziHeaderSearchBar'>
                                            <IoIosSearch style={{width: '20px', height: '20px'}}/>
                                            <input className='comenziHeaderSearchBarInput' placeholder='Cauta dupa order ID sau email utilizator' value={search_bar_input} onChange={handleSearchBarInputChange}/>
                                        </div>
                                    </div>
                                    <div className='comenziListSection'>
                                        <div className='comenziListChooseDiv'>
                                            <div className={ordersCategory === 'paid' ? 'comenziListCategoryBtnActive' : 'comenziListCategoryBtn'} onClick={() => changeOrdersCategory('paid')}>
                                                <p>Platite</p>
                                            </div>
                                            <div className={ordersCategory === 'processing' ? 'comenziListCategoryBtnActive' : 'comenziListCategoryBtn'} onClick={() => changeOrdersCategory('processing')}>
                                                <p>Plata in procesare</p>
                                            </div>
                                            <div className={ordersCategory === 'unpaid' ? 'comenziListCategoryBtnActive' : 'comenziListCategoryBtn'} onClick={() => changeOrdersCategory('unpaid')}>
                                                <p>Neplatite</p>
                                            </div>
                                        </div>
                                        <p style={{textAlign:'left', opacity: '0.5'}}>Comenzile sunt ordonate de la cele mai noi pana la cele mai vechi</p>
                                        {displayList.length > 0 ? (
                                            displayList.map((order, index) => {
                                                return (
                                                    <div className='comenziListItem' key={'order' + index}>
                                                        <div className='comenziListItemGridItem'>
                                                            <p>
                                                                Order ID: <b>{order.id}</b>
                                                            </p>
                                                        </div>
                                                        <div className='comenziListItemGridItem'>
                                                            <p>
                                                                Data: <b>{order.date}</b>
                                                            </p>
                                                        </div>
                                                        <div className='comenziListItemGridItem' style={{ width: '300px' }}>
                                                            <p>
                                                                Client: <b>{order.delivery_info.email || 'Guest'}</b>
                                                            </p>
                                                        </div>
                                                        <div className='comenziListItemGridItem'>
                                                            <p>
                                                                Pret: <b>£{order.price}</b>
                                                            </p>
                                                        </div>
                                                        <div className='comenziDownloadOrderSummaryBtn' onClick={() => openSummaryPopup(order, order.id)}>
                                                            <FaEye />
                                                            <p>View order summary</p>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <p>No orders</p>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        )
    )
}

export default ComenziMaga