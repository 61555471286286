import React, { useState, useEffect, useContext } from 'react'
import Header from '../header/header'
import './success.css'
import { BiHappyBeaming } from "react-icons/bi";
import { useParams, useNavigate } from 'react-router-dom';
import Loader from "react-js-loader";
import axios from 'axios';
import { APIurl } from '../../ApiService';
import { CartContext } from '../shop/cart/cartProvider';

function Success() {
    const { clearCart } = useContext(CartContext);
    const navigate = useNavigate();
    const { order_type, checkout_id } = useParams();

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        //  validate the checkout id
        const validateCheckoutID = async() =>{
            setShowLoader(true);
            const checkoutID = {
                checkout_id: checkout_id
            };
            const response = await axios.post(`${APIurl}/api/completedcheckout`, checkoutID, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });  
            console.log('Server response to checkout complete:');
            console.log(response);
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
            if(responseStatus == 'error'){  //  if server gave error
                navigate('/neworder');
            }else{  //  if server responded ok
                if(dataMessage == 'Good'){
                    setShowLoader(false);
                    return;
                }else{
                    navigate('/login');
                    return;
                }
            } 
        }

        //  validate the shop checkout id
        const validateShopCheckoutID = async() =>{
            setShowLoader(true);
            const checkoutID = {
                checkout_id: checkout_id
            };
            const response = await axios.post(`${APIurl}/api/completedshopcheckout`, checkoutID, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });  
            console.log('Server response to checkout complete:');
            console.log(response);
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
            if(responseStatus == 'error'){  //  if server gave error
                navigate('/neworder');
            }else{  //  if server responded ok
                if(dataMessage == 'Good'){
                    setShowLoader(false);
                    clearCart();
                    return;
                }else{
                    navigate('/login');
                    return;
                }
            } 
        }

        if(order_type == 'cut_edge'){
            validateCheckoutID();
        }else if(order_type == 'shop'){
            validateShopCheckoutID();
        }
    }, []);


    return (
        showLoader == true ? (
            <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
        ) : (
            <div className='successSection'>
                <Header/>
                <div className='successBox'>
                    <div className='successTitle'>
                        <BiHappyBeaming />
                        <p>Thank you for ordering from us!</p>
                    </div>
                    <div className='successMsg'>
                        <p>Your order has been successfully processed! We're excited to begin working on fulfilling your order. </p>
                        <p>Sit tight! Our skilled team is now hard at work ensuring that every detail of your order is executed with precision and care.</p>
                        <p>If you have any questions or need assistance, feel free to reach out to our friendly customer support team. We're here to help!</p>
                        <p>Thank you for choosing MyBoards. We appreciate your trust and look forward to delivering excellence to you.</p>
                    </div>
                </div>
            </div>
        )
    );
}

export default Success