import React, {useState, useEffect, useRef, useContext} from 'react'
import './../shopPage.css'
import './productPage.css'
import Header from '../../header/header';
import { FiSearch } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import { APIurl } from '../../../ApiService';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import autoAnimate from '@formkit/auto-animate';
import { FaCartPlus } from "react-icons/fa6";
import Loader from "react-js-loader";
import { TbAlertHexagonFilled } from "react-icons/tb";
import { CartContext } from '../cart/cartProvider';
import { PiSmileySadLight } from "react-icons/pi";
import { set } from 'lodash';

const ProductPage = () => {
    
    const navigate = useNavigate();
    const { urlproductid } = useParams();
    const cookies = new Cookies();
    const [categories, setCategories] = useState([]);
    const [productInfo, setProductInfo] = useState(null);
    const [categoryInfo, setCategoryInfo] = useState({
        id: 0,
        name: '',
    });
    const [baseOptions, setBaseOptions] = useState([]);
    const [squareMeterOptions, setSquareMeterOptions] = useState([]);
    const [fixedPercentageOptions, setFixedPercentageOptions] = useState([]);
    const [rangePercentageOptions, setRangePercentageOptions] = useState([]);
    const [additionalOptions, setAdditionalOptions] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [quantityAlert, setQuantityAlert] = useState(false);

    const [relatedProducts, setRelatedProducts] = useState([]);

    // Function to fetch categories
    const fetchCategoriesAndProductInfo = async () => {
        try {
            const req_data = {
                product_id: urlproductid,
            };
            const response = await axios.post(`${APIurl}/api/getproductinfo`, req_data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const responseStatus = response.data.status;
            const dataMessage = response.data.data;

            let prod_categ_name = '';
            if (responseStatus !== 'error') {
                if (dataMessage === 'Categories not found') {
                    fetchCategoriesAndProductInfo();
                    return;
                }else if (dataMessage === 'Product fetched') {
                    const response_data = response.data.response_data;
                    const fetched_product_info = response_data.product;
                    fetched_product_info.options = JSON.parse(fetched_product_info.options);
                    setProductInfo(fetched_product_info);
                    setCategories(response_data.categories);
                    setCategoryInfo({
                        id: response_data.category_id,
                        name: response_data.category_name,
                    });
                    prod_categ_name = response_data.category_name;
                }else if (dataMessage === 'Product category not found') {
                    const response_data = response.data.response_data;
                    setCategories(response_data.categories);
                }else if(dataMessage === 'Product not found'){
                    navigate(`/shop`);
                }
            }

            const req_related_products_data = {
                category: prod_categ_name,
                product_id: urlproductid
            };
            const response2 = await axios.post(`${APIurl}/api/getrelatedproducts`, req_related_products_data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const responseStatus2 = response2.data.status;
            const dataMessage2 = response2.data.data;
            if (responseStatus2 !== 'error') {
                if (dataMessage2 === 'Related products fetched') {
                    const response_data2 = response2.data.relatedProducts;
                    setRelatedProducts(response_data2);
                    console.log('Related products fetched');
                    console.log(response_data2);
                }
            }
        } catch (error) {
            console.error("Error fetching product categories:", error);
        }
    };
    useEffect(() => {
        fetchCategoriesAndProductInfo();
    }, [urlproductid]);

    useEffect(() => {
        if(productInfo !== null && productInfo.options !== null){

            const newBaseOptions = [];
            const newSquareMeterOptions = [];
            const newFixedPercentageOptions = [];
            const newRangePercentageOptions = [];
            const newAdditionalOptions = [];
            console.log(productInfo.options);
            const productPriceOptions = productInfo.options;
            for(let i = 0; i < productPriceOptions.length; i++){
                const option = productPriceOptions[i];
                console.log(option);
                if(option.price_type === 'base'){
                    const newOption = {
                        name: option.name,
                        options: option.options,
                        price_type: option.price_type,
                        dropdown_open: false,
                        dropdown_value: option.options[0].name,
                    };
                    newBaseOptions.push(newOption);
                    console.log('Found base price');
                }else if(option.price_type === 'squareMeterFixed'){
                    const newOption = {
                        name: option.name,
                        options: option.options,
                        price_type: option.price_type,
                        dropdown_open: false,
                        dropdown_value: option.options[0].name,
                    };
                    newSquareMeterOptions.push(newOption);
                    console.log('Found square meter fixed price');
                }else if(option.price_type === 'squareMeterRange'){
                    const newOption = {
                        name: option.name,
                        options: option.options,
                        price_type: option.price_type,
                        input_value: parseInt(option.options[0].min),
                        input_alert: false,
                    };
                    newSquareMeterOptions.push(newOption);
                    console.log('Found square meter range price');
                }else if(option.price_type == 'fixedPercentage'){
                    const newOption = {
                        name: option.name,
                        options: option.options,
                        price_type: option.price_type,
                        dropdown_open: false,
                        dropdown_value: option.options[0].name,
                    };
                    newFixedPercentageOptions.push(newOption);
                    console.log('Found fixed percentage price');
                }else if(option.price_type === 'rangePercentage'){
                    const newOption = {
                        name: option.name,
                        options: option.options,
                        price_type: option.price_type,
                        input_value: parseInt(option.options[0].min),
                        input_alert: false,
                    };
                    newRangePercentageOptions.push(newOption);
                    console.log('Found range percentage price');
                }else if(option.price_type === 'additional'){
                    const newOption = {
                        name: option.name,
                        options: option.options,
                        price_type: option.price_type,
                        dropdown_open: false,
                        dropdown_value: option.options[0].name,
                    };
                    newAdditionalOptions.push(newOption);
                    console.log('Found additional price');
                }
            }

            setBaseOptions(newBaseOptions);
            setSquareMeterOptions(newSquareMeterOptions);
            setFixedPercentageOptions(newFixedPercentageOptions);
            setRangePercentageOptions(newRangePercentageOptions);
            setAdditionalOptions(newAdditionalOptions);
        }
    }, [productInfo]);

    const redirectToCategory = (categoryID) => {
        navigate(`/shop/${categoryID}`);
    }

    const productsListRef = useRef(null);
    useEffect(() => {
        if (productsListRef.current) {
            autoAnimate(productsListRef.current);
        }
    }, []);
    const relatedProductsListRef = useRef(null);
    useEffect(() => {
        if (relatedProductsListRef.current) {
            autoAnimate(relatedProductsListRef.current);
        }
    }, []);

    const openOptionsDropdown = (priceOption) => {
        console.log(priceOption);
        if(priceOption.price_type === 'base'){
            const newOption = baseOptions.map(option => ({
                ...option,
                dropdown_open: option.name === priceOption.name
            }));
            setBaseOptions(newOption);
            const newFixedPercentageOptions = fixedPercentageOptions.map(option => ({
                ...option,
                dropdown_open: false
            }));
            setFixedPercentageOptions(newFixedPercentageOptions);
            const newAdditionalOptions = additionalOptions.map(option => ({
                ...option,
                dropdown_open: false
            }));
            setAdditionalOptions(newAdditionalOptions);

        }else if(priceOption.price_type === 'squareMeterFixed'){
            const newOption = baseOptions.map(option => ({
                ...option,
                dropdown_open: false
            }));
            setBaseOptions(newOption);
            const newSquareMeterOptions = squareMeterOptions.map(option => ({
                ...option,
                dropdown_open: option.name === priceOption.name
            }));
            setSquareMeterOptions(newSquareMeterOptions);
            const newFixedPercentageOptions = fixedPercentageOptions.map(option => ({
                ...option,
                dropdown_open: false
            }));
            setFixedPercentageOptions(newFixedPercentageOptions);
            const newAdditionalOptions = additionalOptions.map(option => ({
                ...option,
                dropdown_open: false
            }));
            setAdditionalOptions(newAdditionalOptions);

        }else if(priceOption.price_type === 'fixedPercentage'){
            const newOption = baseOptions.map(option => ({
                ...option,
                dropdown_open: false
            }));
            setBaseOptions(newOption);
            const newFixedPercentageOptions = fixedPercentageOptions.map(option => ({
                ...option,
                dropdown_open: option.name === priceOption.name
            }));
            setFixedPercentageOptions(newFixedPercentageOptions);
            const newAdditionalOptions = additionalOptions.map(option => ({
                ...option,
                dropdown_open: false
            }));
            setAdditionalOptions(newAdditionalOptions);

        }else if(priceOption.price_type === 'additional'){
            const newOption = baseOptions.map(option => ({
                ...option,
                dropdown_open: false
            }));
            setBaseOptions(newOption);
            const newFixedPercentageOptions = fixedPercentageOptions.map(option => ({
                ...option,
                dropdown_open: false
            }));
            setFixedPercentageOptions(newFixedPercentageOptions);
            const newAdditionalOptions = additionalOptions.map(option => ({
                ...option,
                dropdown_open: option.name === priceOption.name
            }));
            setAdditionalOptions(newAdditionalOptions);
        }
    }

    const closeOptionsDropdown = (priceOption) => {
        if(priceOption.price_type === 'base'){
            const newOption = [...baseOptions];
            newOption[newOption.findIndex(x => x.name === priceOption.name)].dropdown_open = false;
            setBaseOptions(newOption);
        }else if(priceOption.price_type === 'squareMeterFixed'){
            const newOption = [...squareMeterOptions];
            newOption[newOption.findIndex(x => x.name === priceOption.name)].dropdown_open = false;
            setSquareMeterOptions(newOption);
        }else if(priceOption.price_type === 'fixedPercentage'){
            const newOption = [...fixedPercentageOptions];
            newOption[newOption.findIndex(x => x.name === priceOption.name)].dropdown_open = false;
            setFixedPercentageOptions(newOption);
        }else if(priceOption.price_type === 'additional'){
            const newOption = [...additionalOptions];
            newOption[newOption.findIndex(x => x.name === priceOption.name)].dropdown_open = false;
            setAdditionalOptions(newOption);
        }
    }

    const optionDropdownSelected = (priceOption, optionIndex) => {
        if(priceOption.price_type === 'base'){
            const newOption = [...baseOptions];
            const option = newOption[newOption.findIndex(x => x.name === priceOption.name)].options[optionIndex];
            newOption[newOption.findIndex(x => x.name === priceOption.name)].dropdown_value = option.name;
            newOption[newOption.findIndex(x => x.name === priceOption.name)].dropdown_open = false;
            console.log(newOption);
            setBaseOptions(newOption);
        }else if(priceOption.price_type === 'squareMeterFixed'){
            const newOption = [...squareMeterOptions];
            const option = newOption[newOption.findIndex(x => x.name === priceOption.name)].options[optionIndex];
            newOption[newOption.findIndex(x => x.name === priceOption.name)].dropdown_value = option.name;
            newOption[newOption.findIndex(x => x.name === priceOption.name)].dropdown_open = false;
            console.log(newOption);
            setSquareMeterOptions(newOption);
        }else if(priceOption.price_type === 'fixedPercentage'){
            const newOption = [...fixedPercentageOptions];
            const option = newOption[newOption.findIndex(x => x.name === priceOption.name)].options[optionIndex];
            newOption[newOption.findIndex(x => x.name === priceOption.name)].dropdown_value = option.name;
            newOption[newOption.findIndex(x => x.name === priceOption.name)].dropdown_open = false;
            console.log(newOption);
            setFixedPercentageOptions(newOption);
        }else if(priceOption.price_type === 'additional'){
            const newOption = [...additionalOptions];
            const option = newOption[newOption.findIndex(x => x.name === priceOption.name)].options[optionIndex];
            newOption[newOption.findIndex(x => x.name === priceOption.name)].dropdown_value = option.name;
            newOption[newOption.findIndex(x => x.name === priceOption.name)].dropdown_open = false;
            console.log(newOption);
            setAdditionalOptions(newOption);
        }
    }

    const rangePercentageOptionInputChanged = (priceOption, optionIndex, inputValue) => {
        if (priceOption.price_type === 'rangePercentage') {
            // Validate inputValue to accept only integers
            const integerValue = inputValue.replace(/\D/g, ''); // Remove any non-digit characters
            // Proceed with the rest of the logic only if integerValue is not empty
            const newOption = [...rangePercentageOptions];
            const optionIndexInArray = newOption.findIndex(x => x.name === priceOption.name);
            const option = newOption[optionIndexInArray].options[optionIndex];
            const min = parseInt(option.min, 10);
            const max = parseInt(option.max, 10);
            if (integerValue === '') {
                // Handle empty input or input that had only non-digit characters
                newOption[optionIndexInArray].input_alert = true;
                newOption[optionIndexInArray].input_value = '';
            } else {
                const intValue = parseInt(integerValue, 10);
                if (intValue < min || intValue > max) {
                    newOption[optionIndexInArray].input_alert = true;
                } else {
                    newOption[optionIndexInArray].input_alert = false;
                }
                newOption[optionIndexInArray].input_value = integerValue;
            }
            setRangePercentageOptions(newOption);
        }else if(priceOption.price_type === 'squareMeterRange'){
            // Validate inputValue to accept only integers
            const integerValue = inputValue.replace(/\D/g, ''); // Remove any non-digit characters
            // Proceed with the rest of the logic only if integerValue is not empty
            const newOption = [...squareMeterOptions];
            const optionIndexInArray = newOption.findIndex(x => x.name === priceOption.name);
            const option = newOption[optionIndexInArray].options[0];
            const min = parseInt(option.min, 10);
            const max = parseInt(option.max, 10);
            if (integerValue === '') {
                // Handle empty input or input that had only non-digit characters
                newOption[optionIndexInArray].input_alert = true;
                newOption[optionIndexInArray].input_value = '';
            } else {
                const intValue = parseInt(integerValue, 10);
                if (intValue < min || intValue > max) {
                    newOption[optionIndexInArray].input_alert = true;
                } else {
                    newOption[optionIndexInArray].input_alert = false;
                }
                newOption[optionIndexInArray].input_value = integerValue;
            }
            setSquareMeterOptions(newOption);
        }
    };

    const handleQuantityChange = (e) => {
        const value = e.target.value;
        // Allow empty input
        if (value === '') {
            setQuantity(value);
            setQuantityAlert(true);
            return;
        }
        // Validate integer input
        const isInteger = /^\d+$/.test(value);
        if (isInteger) {
            const intValue = parseInt(value, 10);
            if (intValue < 1) {
                setQuantityAlert(true);
            } else {
                setQuantityAlert(false);
            }
            setQuantity(value);
        } else {
            // Invalid input
            setQuantityAlert(true);
        }
    };

    const [productPrice, setProductPrice] = useState(0);
    const [priceWithoutVat, setPriceWithoutVat] = useState(0);
    const calculatePrice = () => {
        // check if all the range options inputs are valid
        let range_inputs_good = true;
        if(rangePercentageOptions.find(x => x.input_alert === true) !== undefined){
            range_inputs_good = false;
        }
        let squareMeter_inputs_good = true;
        if(squareMeterOptions.find(x => x.input_alert === true) !== undefined){
            squareMeter_inputs_good = false;
        }
        if(range_inputs_good == true && quantityAlert == false && squareMeter_inputs_good == true){
            let price = 0;
            //  look for base price options
            let basePrice = 0;
            if(baseOptions.length > 0){
                for(let i = 0; i < baseOptions.length; i++){
                    const option = baseOptions[i];
                    if(option.dropdown_value !== ''){
                        basePrice += parseFloat(option.options.find(x => x.name === option.dropdown_value).value);
                    }
                }
                console.log(basePrice);
            }

            //  look for square meter fixed price options
            let squareMeterValues = 1;
            if(squareMeterOptions.length > 0){
                for(let i = 0; i < squareMeterOptions.length; i++){
                    const option = squareMeterOptions[i];
                    if(option.dropdown_value !== '' && option.dropdown_value !== null && option.dropdown_value !== undefined){
                        squareMeterValues = squareMeterValues * parseInt(option.options.find(x => x.name === option.dropdown_value).value)/1000;
                    }else if(option.input_value !== '' && option.input_value !== null && option.input_value !== undefined){
                        squareMeterValues = squareMeterValues * parseInt(option.input_value)/1000;
                    }
                }
                console.log(squareMeterValues);
            }

            let price_after_square_meter = basePrice * squareMeterValues;
            price = price_after_square_meter;
    
            //  look for fixed percentage price options
            if(fixedPercentageOptions.length > 0){
                for(let i = 0; i < fixedPercentageOptions.length; i++){
                    const option = fixedPercentageOptions[i];
                    if(option.dropdown_value !== ''){
                        let fixedPercentagePrice = parseFloat(option.options.find(x => x.name === option.dropdown_value).value);
                        price = price + (price_after_square_meter * (fixedPercentagePrice / 1000));
                    }
                }
            }
    
            //  look for range percentage price options
            let rangePercentagePrice = 0;
            if(rangePercentageOptions.length > 0){
                for(let i = 0; i < rangePercentageOptions.length; i++){
                    const option = rangePercentageOptions[i];
                    if(option.input_value !== ''){
                        rangePercentagePrice += parseFloat(option.input_value);
                        let option_value = parseFloat(option.input_value);
                        price = price + (price_after_square_meter * (option_value / 1000));
                    }
                }
            }
    
            // Look for additional price options
            let additionalPrice = 0;
            if (additionalOptions.length > 0) {
                for (let i = 0; i < additionalOptions.length; i++) {
                    const option = additionalOptions[i];
                    if (option.dropdown_value !== '') {
                        additionalPrice += parseFloat(option.options.find(x => x.name === option.dropdown_value).value);
                    }
                }
                price += additionalPrice; // Add the total additional price once
            }
    
            console.log(basePrice, rangePercentagePrice, additionalPrice);
            console.log(price.toFixed(2));
            price = price.toFixed(2) * quantity;
            setProductPrice(price.toFixed(2));
            setPriceWithoutVat((price / 1.20).toFixed(2));
        }
    }

    useEffect(() => {
        if((baseOptions.length !== 0 || squareMeterOptions.length !== 0 || fixedPercentageOptions.length !== 0 || rangePercentageOptions.length !== 0 || additionalOptions.length !== 0) && quantity !== 0){
            calculatePrice();
        }
    }, [baseOptions, squareMeterOptions,fixedPercentageOptions, rangePercentageOptions, additionalOptions, quantity]);

    const redirectToProduct = (productID) => {
        navigate(`/shop/product/${productID}`);
    }

    const { addItemToCart } = useContext(CartContext);
    const [add_to_cart_loader, setAdd_to_cart_loader] = useState(false);
    const sendItemToCart = async() => {
        let range_inputs_good = true;
        if(rangePercentageOptions.find(x => x.input_alert === true) !== undefined){
            range_inputs_good = false;
        }
        if(range_inputs_good == true && quantityAlert == false){
            setAdd_to_cart_loader(true);
            const product_to_add = {
                id: productInfo.id,
                baseOptions: baseOptions,
                squareMeterOptions: squareMeterOptions,
                fixedPercentageOptions: fixedPercentageOptions,
                rangePercentageOptions: rangePercentageOptions,
                additionalOptions: additionalOptions,
                quantity: quantity,
            };
            const cartResponse = await addItemToCart(product_to_add);
            setAdd_to_cart_loader(false);
        }
    }

    const placeholderImage = process.env.PUBLIC_URL + '/res/images/placeholder.jpg';

    return (
        <div className='shop-section'>
            <Header />
            <div className='shop-product-hero-container'>
                <p>Browse and shop for the best quality materials for your project</p>
            </div>
            <div className='shop-container'>
                <div className='shop-categories-list'>
                    <p style={{fontWeight: '500'}}>Shop for:</p>
                    {categories?.map((category, index) => (
                        <div key={'category' + index} className={category.name === categoryInfo.name ? 'shop-category-selected' : 'shop-category'} onClick={() => redirectToCategory(category.id)}>
                            <p>{category.name}</p>
                        </div>
                    ))}
                </div>

                <div className='shop-products-container'>
                    <div className='shop-products-header-product'>
                        <IoReturnUpBackOutline className='shop-products-header-back-btn' onClick={() => navigate(`/shop/${categoryInfo.id}`)}/>
                        <p className='shop-products-header-category' onClick={() => navigate(`/shop/${categoryInfo.id}`)}>{categoryInfo.name}</p>
                        <p style={{opacity: 0.5}}>/</p>
                        <p style={{fontWeight: '500'}}>{productInfo !== null ? productInfo.name : ''}</p>
                    </div>
                    <div className='shop-mobile-products-header-product' onClick={() => navigate(`/shop/${categoryInfo.id}`)}>
                        <IoReturnUpBackOutline className='shop-products-header-back-btn' />
                        <p className='shop-products-header-category' >{categoryInfo.name}</p>
                    </div>
                    <div className='product-page-section'>
                        <div className='product-page-section-upper'>
                            <div className='product-page-section-upper-left'>
                                <img src={productInfo?.image !== null ? productInfo?.image : process.env.PUBLIC_URL + '/res/images/placeholder.jpg'} 
                                    alt={productInfo?.name}
                                    onError={(e) => {
                                        e.target.onerror = null; // Prevents looping
                                        e.target.src = placeholderImage;
                                    }}
                                    className='product-page-section-upper-left-image'
                                />
                            </div>
                            <div className='product-page-section-upper-right'>
                                <div className='product-page-section-upper-right-top'>
                                    <div className='product-page-section-upper-right-top-left-container'>
                                        <p className='product-page-name'>{productInfo !== null ? productInfo.name : ''}</p>
                                    </div>
                                </div>
                                <div className='product-page-section-upper-right-configure-section'>
                                    <p>Configure product to your liking:</p>
                                    <div className='product-page-section-upper-right-configure-options-list'>
                                        {productInfo !== null && baseOptions.length > 0 ? baseOptions.map((configure_option, index) => (
                                            <div key={'product-page-section-upper-right-configure-options-list-item' + index} className='product-page-section-upper-right-configure-options-list-item'>
                                                <p className='product-page-price-option-name'>{configure_option.name}:</p>
                                                <div className='product-page-price-option-dropdown'>
                                                    <div className='product-page-price-option-dropdown-top' style={{cursor: 'pointer'}} onClick={() => configure_option.dropdown_open === true ? closeOptionsDropdown(configure_option) : openOptionsDropdown(configure_option)}>
                                                        <div className='product-page-price-option-dropdown-top-value'>
                                                            <p>{configure_option.dropdown_value !== '' ? configure_option.dropdown_value  : 'Alege o categorie'}</p>
                                                        </div>
                                                        {configure_option.dropdown_open === true ? <FaAngleUp className='product-page-price-option-dropdown-top-icon'/> : <FaAngleDown className='product-page-price-option-dropdown-top-icon'/>}
                                                    </div>
                                                    {configure_option.dropdown_open === true ?
                                                        (<div className='product-page-price-option-dropdown-menu'>
                                                        {configure_option.options.map((option, optionIndex) => (
                                                            <div key={'option' + optionIndex} className='product-page-price-option-dropdown-menu-item' onClick={() => optionDropdownSelected(configure_option, optionIndex)}><p>{option.name}</p></div>
                                                        ))}
                                                        </div>) : null}
                                                </div>
                                            </div>
                                        )) : null}
                                        {productInfo !== null && squareMeterOptions.length > 0 ? squareMeterOptions.map((configure_option, index) => (
                                            configure_option.price_type === 'squareMeterFixed' ? (
                                                <div key={'product-page-section-upper-right-configure-options-list-item' + index} className='product-page-section-upper-right-configure-options-list-item'>
                                                    <p className='product-page-price-option-name'>{configure_option.name}:</p>
                                                    <div className='product-page-price-option-dropdown'>
                                                        <div className='product-page-price-option-dropdown-top' style={{cursor: 'pointer'}} onClick={() => configure_option.dropdown_open === true ? closeOptionsDropdown(configure_option) : openOptionsDropdown(configure_option)}>
                                                            <div className='product-page-price-option-dropdown-top-value'>
                                                                <p>{configure_option.dropdown_value !== '' ? configure_option.dropdown_value  : 'Alege o categorie'}</p>
                                                            </div>
                                                            {configure_option.dropdown_open === true ? <FaAngleUp className='product-page-price-option-dropdown-top-icon'/> : <FaAngleDown className='product-page-price-option-dropdown-top-icon'/>}
                                                        </div>
                                                        {configure_option.dropdown_open === true ?
                                                            (<div className='product-page-price-option-dropdown-menu'>
                                                            {configure_option.options.map((option, optionIndex) => (
                                                                <div key={'option' + optionIndex} className='product-page-price-option-dropdown-menu-item' onClick={() => optionDropdownSelected(configure_option, optionIndex)}><p>{option.name}</p></div>
                                                            ))}
                                                            </div>) : null}
                                                    </div>
                                                </div>
                                            ) : configure_option.price_type === 'squareMeterRange' ? (
                                                <div key={'product-page-section-upper-right-configure-options-list-item' + index} className='product-page-section-upper-right-configure-options-list-item'>
                                                    <p className='product-page-price-option-name'>{configure_option.name} [mm]:</p>
                                                    <input type='number' className='product-page-price-option-input' value={configure_option.input_value} onChange={(e) => rangePercentageOptionInputChanged(configure_option, index, e.target.value)}/>
                                                    {configure_option.input_alert == true ? (
                                                        <div className='product-page-price-option-input-alert'>
                                                            <TbAlertHexagonFilled className='product-page-price-option-input-alert-icon'/>
                                                            <p>{configure_option.name} value must be between {configure_option.options[0].min} and {configure_option.options[0].max}</p>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null
                                        )) : null}
                                        {productInfo !== null && fixedPercentageOptions.length > 0 ? fixedPercentageOptions.map((configure_option, index) => (
                                            <div key={'product-page-section-upper-right-configure-options-list-item' + index} className='product-page-section-upper-right-configure-options-list-item'>
                                                <p className='product-page-price-option-name'>{configure_option.name}:</p>
                                                <div className='product-page-price-option-dropdown'>
                                                    <div className='product-page-price-option-dropdown-top' style={{cursor: 'pointer'}} onClick={() => configure_option.dropdown_open === true ? closeOptionsDropdown(configure_option) : openOptionsDropdown(configure_option)}>
                                                        <div className='product-page-price-option-dropdown-top-value'>
                                                            <p>{configure_option.dropdown_value !== '' ? configure_option.dropdown_value  : 'Alege o categorie'}</p>
                                                        </div>
                                                        {configure_option.dropdown_open === true ? <FaAngleUp className='product-page-price-option-dropdown-top-icon'/> : <FaAngleDown className='product-page-price-option-dropdown-top-icon'/>}
                                                    </div>
                                                    {configure_option.dropdown_open === true ?
                                                        (<div className='product-page-price-option-dropdown-menu'>
                                                        {configure_option.options.map((option, optionIndex) => (
                                                            <div key={'option' + optionIndex} className='product-page-price-option-dropdown-menu-item' onClick={() => optionDropdownSelected(configure_option, optionIndex)}><p>{option.name}</p></div>
                                                        ))}
                                                        </div>) : null}
                                                </div>
                                            </div>
                                        )) : null}
                                        {productInfo !== null && rangePercentageOptions.length > 0 ? rangePercentageOptions.map((configure_option, index) => (
                                            <div key={'product-page-section-upper-right-configure-options-list-item' + index} className='product-page-section-upper-right-configure-options-list-item'>
                                                <p className='product-page-price-option-name'>{configure_option.name} [mm]:</p>
                                                <input type='number' className='product-page-price-option-input' value={configure_option.input_value} onChange={(e) => rangePercentageOptionInputChanged(configure_option, 0, e.target.value)}/>
                                                {configure_option.input_alert == true ? (
                                                    <div className='product-page-price-option-input-alert'>
                                                        <TbAlertHexagonFilled className='product-page-price-option-input-alert-icon'/>
                                                        <p>{configure_option.name} value must be between {configure_option.options[0].min} and {configure_option.options[0].max}</p>
                                                    </div>
                                                ) : null}
                                            </div>
                                        )) : null}
                                        {productInfo !== null && additionalOptions.length > 0 ? additionalOptions.map((configure_option, index) => (
                                            <div key={'product-page-section-upper-right-configure-options-list-item' + index} className='product-page-section-upper-right-configure-options-list-item'>
                                                <p className='product-page-price-option-name'>{configure_option.name}:</p>
                                                <div className='product-page-price-option-dropdown'>
                                                    <div className='product-page-price-option-dropdown-top' style={{cursor: 'pointer'}} onClick={() => configure_option.dropdown_open === true ? closeOptionsDropdown(configure_option) : openOptionsDropdown(configure_option)}>
                                                        <div className='product-page-price-option-dropdown-top-value'>
                                                            <p>{configure_option.dropdown_value !== '' ? configure_option.dropdown_value  : 'Alege o categorie'}</p>
                                                        </div>
                                                        {configure_option.dropdown_open === true ? <FaAngleUp className='product-page-price-option-dropdown-top-icon'/> : <FaAngleDown className='product-page-price-option-dropdown-top-icon'/>}
                                                    </div>
                                                    {configure_option.dropdown_open === true ?
                                                        (<div className='product-page-price-option-dropdown-menu'>
                                                        {configure_option.options.map((option, optionIndex) => (
                                                            <div key={'option' + optionIndex} className='product-page-price-option-dropdown-menu-item' onClick={() => optionDropdownSelected(configure_option, optionIndex)}><p>{option.name}</p></div>
                                                        ))}
                                                        </div>) : null}
                                                </div>
                                            </div>
                                        )) : null}
                                        <div className='product-page-section-upper-right-configure-options-list-item'>
                                            <p className='product-page-price-option-name'>Quantity:</p>
                                            <input type='number' className='product-page-section-upper-right-quantity-input' value={quantity} onChange={(e) => handleQuantityChange(e)}/>
                                            {quantityAlert == true ? (
                                                <div className='product-page-price-option-input-alert'>
                                                    <TbAlertHexagonFilled className='product-page-price-option-input-alert-icon'/>
                                                    <p>Minimum quantity is 1</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='product-page-section-upper-right-pricing-container' style={{marginTop: '15px'}}>
                                        <p className='product-page-section-upper-right-pricing-container-price'>Price: <span style={{fontWeight: '500'}}>£{productPrice}</span></p>
                                        <p className='product-page-section-upper-right-pricing-container-price-without-vat'>Without VAT: <span style={{fontWeight: '500'}}>£{priceWithoutVat}</span></p>
                                    </div>
                                    <div className='product-page-section-upper-right-pricing-container' style={{marginTop: '15px'}}>
                                        {productInfo?.stock ? (
                                            <div className='product-page-add-to-cart-btn' onClick={add_to_cart_loader ? null : sendItemToCart}>
                                                {add_to_cart_loader ? (
                                                    <Loader type="spinner-default" bgColor='white' color={'transparent'} size={20} />
                                                ) : (
                                                    <>
                                                        <FaCartPlus className='product-page-add-to-cart-btn-icon' />
                                                        <p>Add to cart</p>
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <div className='product-page-no-stock-notify'>
                                                <PiSmileySadLight className='product-page-no-stock-notify-icon'/>
                                                <p>Out of stock</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='product-page-section-lower'>
                            <p style={{fontWeight: '500', fontSize: '18px'}}>Description</p>
                            <pre className='product-page-description'>
                                {productInfo !== null ? productInfo.description : ''}
                            </pre>
                        </div>

                    </div>

                    {relatedProducts.length > 0 ? (
                    <div className='product-page-related-products-container'>
                        <p style={{fontWeight: '500', fontSize: '18px'}}>Related products</p>
                        <div className='product-page-related-products' ref={relatedProductsListRef}>
                            {relatedProducts.map((product, index) => (
                                <div key={'produse-container-list-item' + index} className='shop-products-list-item' onClick={() => redirectToProduct(product.id)}>
                                    <div className='shop-products-list-item-image'>
                                        <img src={product?.image !== null ? product?.image : process.env.PUBLIC_URL + '/res/images/placeholder.jpg'} 
                                        alt={product?.name}
                                        onError={(e) => {
                                            e.target.onerror = null; // Prevents looping
                                            e.target.src = placeholderImage;
                                        }}/>
                                    </div>
                                    <div className='shop-products-list-item-info-body'>
                                        <div className='produse-container-list-item-info'>
                                            <p className='produse-container-list-item-info-code'>{product.category}</p>
                                            <p className='produse-container-list-item-info-title'>{product.name}</p>
                                            <p className='produse-container-list-item-info-code'>{product.stock ? 'In Stock' : 'Out of Stock'}</p>
                                            <p className='produse-container-list-item-info-price'>£{product.price.toFixed(2)}</p>
                                        </div>
                                        <div className='shop-products-list-item-btn-container'>
                                            <div className='shop-products-list-item-buy-btn'>
                                                <p>Configure</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> ) : null}
                </div>
            </div>
        </div>
    )
}

export default ProductPage